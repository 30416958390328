import { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from 'yup';
import axios from "axios";
// @mui
import {
    Box, Button, Grid, Dialog, DialogTitle, DialogContent, DialogActions, ListItemText, ListItemIcon, Select,
    DialogContentText, TextField, FormControl, FormControlLabel, FormLabel, MenuItem
} from "@mui/material";
import { green, pink, deepPurple, amber } from '@mui/material/colors';
// MUI Icons
// import WarehouseIcon from '@mui/icons-material/Warehouse'; // 1 Store 
import EventSeatIcon from '@mui/icons-material/EventSeat'; // 2 Reserved
import LocalShippingIcon from '@mui/icons-material/LocalShipping'; // 3 Shipping
// import DownloadDoneIcon from '@mui/icons-material/DownloadDone'; // 4 Installed
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn'; // 5 Return
import BuildCircleIcon from '@mui/icons-material/BuildCircle'; // 6 Repairing
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'; // 7 Scrap


const stageList = [
    // {
    //     "code": "1",
    //     "label": "Store",
    //     icon: <WarehouseIcon sx={{ color: deepPurple[500] }} />
    // },
    {
        "code": "2",
        "label": "Reserved",
        icon: <EventSeatIcon sx={{ color: amber[500] }} />
    },
    {
        "code": "3",
        "label": "Shipping",
        icon: <LocalShippingIcon sx={{ color: pink[500] }} />
    },
    // {
    //     "code": "4",
    //     "label": "Installed",
    //     icon: <DownloadDoneIcon sx={{ color: green[500] }} />
    // },
    {
        "code": "5",
        "label": "Returned",
        icon: <KeyboardReturnIcon sx={{ color: '#FBBD08' }} />
    },
    {
        "code": "6",
        "label": "Repairing",
        icon: <BuildCircleIcon sx={{ color: '#F2711C' }} />
    },
    {
        "code": "7",
        "label": "Scrapped",
        icon: <DeleteForeverIcon sx={{ color: '#DB445F' }} />
    },
]


const validationSchema = Yup.object().shape({
    name: Yup.string().required('Name is required')
});

export default function UpdateStageDialog({ open, handleClose, getData, setMessage, selectedRows, handleSnakbarOpen }) {
    const selectedSerials = selectedRows.map(r => r.serial);

    const [buyerList, setBuyerList] = useState([]);

    const getBuyerList = async () => {
        const response = await axios.get('/distributors/getListType2');
        const result = await response.data;
        setBuyerList(result.data);
    }

    const postData = async (data) => {
        const response = await axios.post('/warehouse/tracking', data);
        const result = await response.data;
        setMessage(result);
        handleClose();
        handleSnakbarOpen();
        getData();
    }
    // const handleSubmit = (values) => {
    //     // console.log(values);
    //     // setSubmitting(false);
    //     postData(values);
    //     handleClose();
    //     getData();
    //     // setMessage(true);
    // };

    const formik = useFormik({
        initialValues: {
            serials: [],
            stage: '',
            buyer: ''
        },
        onSubmit: (values) => {
            values.serials = selectedSerials;
            postData(values);
        }
    })

    useEffect(() => {
        if (open) {
            getBuyerList();
        }

        return () => {
            // setStage('1');
            getBuyerList([]);
        }
    }, [open]);

    return (
        <Box>
            <Dialog
                fullWidth={true}
                maxWidth='sm'
                open={open}
                onClose={handleClose}
            >
                <form
                    onSubmit={formik.handleSubmit}
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        m: 'auto',
                        width: 'fit-content',
                    }}
                >
                    <DialogTitle>Change Stage</DialogTitle>
                    <DialogContent>
                        {/* <DialogContentText>
                                    Add new distributor's name. For us distributor is b2b business.
                                </DialogContentText> */}
                        <FormControl fullWidth size="small">
                            <FormLabel component="legend">Select Stage</FormLabel>
                            <Select
                                // size="small"
                                // select
                                id="stage"
                                name="stage"
                                value={formik.values.stage}
                                onChange={formik.handleChange}
                                error={formik.touched.stage && Boolean(formik.errors.stage)}
                                helperText={formik.touched.stage && formik.errors.stage}
                            >
                                {
                                    stageList.map(s => (
                                        <MenuItem key={s.code} value={s.code} dense>
                                            <Grid
                                                container
                                                direction='row'
                                                justifyContent='flex-start'
                                                alignItems='center'
                                                spacing={0}
                                            >
                                                <Grid item sx={{ mx: 1 }}>{s.icon}</Grid>
                                                <Grid item sx={{ mx: 1 }}>{s.label}</Grid>
                                            </Grid>
                                        </MenuItem>
                                    ))
                                }
                            </Select>
                        </FormControl>


                        <FormControl fullWidth>
                            <FormLabel component="legend">{
                                'Select Buyer'
                            }</FormLabel>
                            {
                                <TextField
                                    size="small"
                                    select
                                    id="buyer"
                                    name="buyer"
                                    value={formik.values.buyer}
                                    onChange={formik.handleChange}
                                    error={formik.touched.buyer && Boolean(formik.errors.buyer)}
                                    helperText={formik.touched.buyer && formik.errors.buyer}
                                >
                                    {buyerList.map(d => (
                                        <MenuItem key={d._docId} value={d._docId}>
                                            {d.name}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            }
                        </FormControl>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>Cancel</Button>
                        <Button variant="contained" type="submit">Update</Button>
                    </DialogActions>

                </form>
            </Dialog>
        </Box>
    )
}