import * as React from "react";
import {
    Routes, Route
} from "react-router-dom";

import ProductList from "./ProductList.page";
import CreateProduct from "../Forms/CreateProduct.component";
// Product Component
export default function Products(props) {
    return (
        <Routes>
            <Route path="/*" element={<ProductList />} />
            <Route path="/add" element={<CreateProduct />} />
        </Routes>
    );
}