import { useState, useEffect } from "react";
import _ from 'lodash';
import { useNavigate } from "react-router-dom";
import axios from 'axios';
// MUI Components
import {
    Box, Grid, Button, IconButton, Checkbox,
} from "@mui/material";
// Mui Icons
import SyncIcon from '@mui/icons-material/Sync';
import DeleteIcon from "@mui/icons-material/Delete";
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
// Custom Components
import Search from "../../components/Search/Search.component";
import DataTable from "../../components/DataTable/DataTable.component";
const columns = [
    {
        name: "Name",
        selector: row => row.name,
        sortable: true,
    },
    {
        name: "Email",
        selector: row => row.email,
        sortable: true,
    },
    {
        name: "Role",
        selector: row => row.roleName,
        sortable: true,
    }
];
const Title = () => {
    const navigate = useNavigate();
    return (
        <Button
            variant="contained"
            size="small"
            startIcon={<PersonAddAltIcon />}
            onClick={() => navigate('add')}
        >Invite User</Button>
    )
}
const actions = ({ handleChange, getData }) => (
    <Grid container
        alignItems="flex-start"
        justifyContent="flex-end"
        spacing={1}
    >
        <Grid item xs={12} sm={12} md={"auto"} lg={"auto"}>
            <IconButton aria-label="refresh" onClick={getData}>
                <SyncIcon />
            </IconButton>
        </Grid>
        <Grid item xs={12} sm={12} md={"auto"} lg={"auto"}>
            <Search id={"search-distributor"} placeholder={"Search..."} handleChange={handleChange} />
        </Grid>
        {/* <Grid item xs={12} sm={12} md={"auto"} lg={"auto"}>
            <Button
                variant="contained"
                size="small"
                sx={{ float: 'right' }}
                startIcon={<PersonAddAltIcon />}
                onClick={handleOpen}
            >Add</Button>
        </Grid> */}
        <Grid item xs={12} sm={12} md={"auto"} lg={"auto"}>
            <Button disabled variant="contained" size="small" sx={{ float: 'right' }} endIcon={<FilterAltIcon />}>Filter</Button>
        </Grid>
    </Grid>
);
const contextActions = deleteHandler => (
    <IconButton onClick={deleteHandler}>
        <DeleteIcon />
    </IconButton>
);
function SearchQuery(queryString, data) {
    const query = queryString.toLowerCase();
    const filteredData = data.filter((d) => {
        const n = d.name.toLowerCase();
        const e = d.email.toLowerCase();
        return n.includes(query) || e.includes(query);
    }, data);
    return filteredData
}
const UserList = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [queryInput, setQueryInput] = useState('');
    const [selectedRows, setSelectedRows] = useState([]);
    const [data, setData] = useState([]);
    const [toggleCleared, setToggleCleared] = useState(false);


    const handleQueryInput = (e) => {
        const query = e.target.value.toLowerCase();
        setQueryInput(query);
    }
    const handleChange = (s) => {
        setSelectedRows(s.selectedRows);
    }
    const deleteAll = () => {
        const rows = selectedRows.map(r => r.name);
        if (window.confirm(`Are you sure you want to delete?`)) {
            setToggleCleared(!toggleCleared);
            setData(_.differenceBy(data, rows, 'name'));
        }
    }
    const getData = async () => {
        const response = await axios.get('/users');
        const result = await response.data;
        setData(result.data);
    }

    useEffect(() => {
        getData();
    }, []);

    useEffect(() => {
        if (!_.isEmpty(data)) setLoading(false);
    }, [data])
    return (
        <Box>
            < Box sx={{ flexGrow: 1, my: 2 }}>
                <DataTable
                    title={<Title />}
                    columns={columns}
                    data={SearchQuery(queryInput, data)}
                    defaultSortField="name"
                    defaultSortFieldId={1}
                    actions={actions({ handleChange: handleQueryInput, getData })}
                    contextActions={contextActions(deleteAll)}
                    onSelectedRowsChange={handleChange}
                    selectableRowsNoSelectAll
                    clearSelectedRows={toggleCleared}
                    // onRowClicked={handleRowClicked}
                    progressPending={loading}
                />
            </Box >
        </Box >
    )
}

export default UserList;