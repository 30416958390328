import * as React from "react"
import dayjs from "dayjs";
import localizedFormat from 'dayjs/plugin/localizedFormat';
import {
    useTheme, styled
} from "@mui/material/styles";
// MUI Components
import {
    Box, Typography, Card, CardContent, CardMedia, IconButton
} from "@mui/material";
// MUI Icons
const cardStyle = {
    display: 'flex',
    backgroundImage: 'linear-gradient(-65deg, #ABD9E6 0%, #48A89D 27%, #134E5E 93%)',
}
const cardContentStyle = {
    display: 'flex',
    flexDirection: 'column',
    pb: 0,
    // backgroundColor: '#48A89D',
    backgroundColor: 'rgba(0,0,0,0)',
    // backgroundImage: 'linear-gradient(135deg, #ABD9E6 0%, #48A89D 27%, #134E5E 93%)',
    height: '100%'
}
const cardMediaStyle = {
    width: 151,
    backgroundColor: 'rgba(0,0,0,0)',

}

const ProductCard = (props) => {
    // const theme = useTheme();
    dayjs.extend(localizedFormat)
    const product = props.info;

    return (
        <Card sx={cardStyle}>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <CardContent sx={cardContentStyle}>
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'flex-start',
                        // pl: 3, 
                        // pb: 3 
                        p: 1,
                        mb: 1
                    }}>
                        <Typography variant="h4" color="#fff" component="div" noWrap>
                            {
                                product.model ? product.model : ""
                            }
                        </Typography>

                    </Box>
                    {/* <hr width="100%" /> */}
                    <Typography variant="subtitle1" color="#eceff1" component="div" noWrap>
                        {
                            product.createdBy ? product.createdBy : ""
                        }
                    </Typography>
                    <Typography variant="caption" color="#e1e1e1" component="div">
                        {/* {"Apr 7, 2023 12:58 PM"} */}
                        {
                            product.createdAt ? dayjs(product.createdAt).format('lll') : ""
                        }
                    </Typography>

                </CardContent>
            </Box >
            <CardMedia
                component="img"
                sx={cardMediaStyle}
                // src="https://www.atreyo.in/sites/default/files/styles/max_1300x1300/public/a-gallery-products/AG-802-01.png"
                src={product.imageUrl}
                alt="Product Name"
            />
        </Card >
    )
};

export default ProductCard;