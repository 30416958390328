import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import _, { isEmpty } from 'lodash';
// Mui Components
import {
    Box, Button, IconButton, Stack, FormControl, FormLabel, Grid, MenuItem,
    TextField, Typography, Divider, Snackbar, Alert
} from '@mui/material';
import { styled } from "@mui/material/styles";

// Material Icons
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import RemoveCircleRoundedIcon from '@mui/icons-material/RemoveCircleRounded';
import AddStockIllustration from "../../assets/AddStockIllustration2.png";
// Custom Components
import SectionHeader from '../../components/SectionHeader/SectionHeader.component';

const Item = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));
// Validation Schema
const validationSchema = Yup.object().shape({
    model: Yup.string().ensure().uuid("Invalid model id").required("Model is required"),
    serials: Yup.array().of(
        Yup.string()
            .ensure()
            .matches(/^([A-Za-z0-9]{4}-){3}[A-Za-z0-9]{4}$/, "Invalid serial type")
            .required('Serial is required')
    ).min(1, "Atleast 1 serial is required").required()
})
const initialValues = {
    model: '',
    serials: [],
};

const AddStock = () => {
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState({});
    const [modelList, setModelList] = useState([]);
    const navigate = useNavigate();


    const handleAddFeature = () => {
        const serials = [...formik.values.serials];
        serials.push("");
        formik.setFieldValue('serials', serials);
    };
    const handleRemoveFeature = (index) => {
        const serials = [...formik.values.serials];
        serials.splice(index, 1);
        formik.setFieldValue('serials', serials);
    };

    const handleSerialChange = (index, value) => {
        const serials = [...formik.values.serials];
        serials[index] = value;
        formik.setFieldValue('serials', serials);
    }
    const handleClose = () => setOpen(false);
    const getModels = async () => {
        const response = await axios.get('/models/getModelListType2');
        const result = await response.data;
        setModelList(result.data);
    }

    const postData = async (data) => {
        const response = await axios.post('/warehouse/stock/createStock', data);
        const result = await response.data;
        setMessage(result);
        setOpen(true);
    }
    const formik = useFormik({
        initialValues,
        // validationSchema: validationSchema,
        onSubmit: (values,) => {
            // Save the form values
            postData(values);
        },
    });

    useEffect(() => {
        getModels();
        return () => {
            formik.resetForm(initialValues);
        }
    }, []);

    useEffect(() => {
        if (!_.isEmpty(message) && !open && message.status) {
            navigate('/warehouse', { replace: true });
        }
    }, [open]);

    return (
        <Box>

            <form onSubmit={formik.handleSubmit}>
                <Stack
                    direction="row"
                    divider={<Divider orientation="vertical" flexItem />}
                    justifyContent="space-evenly"
                    alignItems="flex-start"
                    spacing={2}
                >
                    <Item>
                        <Stack
                            direction="column"
                            justifyContent="flex-start"
                            alignItems="center"
                            spacing={1}
                        >
                            <Item>
                                <Typography variant="h6" >
                                    Serials
                                </Typography>
                            </Item>
                            <Item>
                                <Stack
                                    direction="column"
                                    justifyContent="center"
                                    alignItems="center"
                                    spacing={1}
                                >
                                    {
                                        formik.values.serials.map((serial, index) => (
                                            <Item key={index}>
                                                <Grid
                                                    container
                                                    justifyContent="center"
                                                    alignItems="stretch"
                                                    spacing={2}

                                                >
                                                    <Grid item xs={11} md={11}>
                                                        <FormControl fullWidth
                                                            error={formik.touched.serials && Boolean(formik.errors.serials)}
                                                        >
                                                            <FormLabel component="legend">Serial {index + 1}</FormLabel>
                                                            <TextField

                                                                size="small"
                                                                name={`serials[${index}]`}
                                                                type="text"
                                                                value={serial}
                                                                // onChange={(e) => handleSerialChange(index, e.target.value)}
                                                                onChange={formik.handleChange}
                                                                onBlur={formik.handleBlur}
                                                                placeholder="Enter Serial"
                                                            // error={formik.touched.serials &&
                                                            //     formik.errors.serials &&
                                                            //     formik.errors.serials[index]}
                                                            // helperText={formik.touched.serials && formik.errors.serials[index]}
                                                            />

                                                        </FormControl>
                                                    </Grid>
                                                    <Grid item xs={1} md={1}>
                                                        <IconButton
                                                            sx={{ mt: 3 }}
                                                            onClick={() => handleRemoveFeature(index)}
                                                            variant="outlined"
                                                            aria-label="Remove"
                                                            color="error"
                                                        >
                                                            <RemoveCircleRoundedIcon />
                                                        </IconButton>
                                                    </Grid>
                                                </Grid>
                                            </Item>
                                        ))
                                    }
                                </Stack>
                            </Item>
                            <Item>
                                <IconButton variant="contained"
                                    onClick={handleAddFeature}
                                    aria-label="Add Feature"
                                    color="warning"
                                >
                                    <AddCircleRoundedIcon />
                                </IconButton>
                            </Item>
                        </Stack>
                    </Item>
                    <Item>
                        <Stack
                            sx={{ mt: 10 }}
                            direction="column"
                            justifyContent="flex-start"
                            alignItems="stretch"
                            spacing={3}
                        >
                            <Item>
                                <FormControl fullWidth>
                                    <FormLabel component="legend">Select Model</FormLabel>
                                    <TextField
                                        size="small"
                                        select
                                        id='model'
                                        name='model'
                                        value={formik.values.model}
                                        onChange={formik.handleChange}
                                        error={formik.touched.model && Boolean(formik.errors.model)}
                                        helperText={formik.touched.model && formik.errors.model}
                                    >
                                        {
                                            modelList.map((model) => (
                                                <MenuItem key={model._docId} value={model._docId}>
                                                    {model.model}
                                                </MenuItem>
                                            ))
                                        }
                                    </TextField>
                                </FormControl>
                            </Item>
                            <Item>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    // disabled={!formik.isValid}
                                    fullWidth>
                                    Add Stock
                                </Button>
                            </Item>
                            <Item sx={{ mt: 2 }}>
                                <img src={AddStockIllustration} width='250px' alt='create icon' />
                            </Item>
                            {
                                formik.touched.serials && formik.errors.isEmptySerial ?
                                    (
                                        <Item style={{ color: "red" }}>{formik.errors.isEmptySerial}</Item>
                                    ) : null
                            }
                        </Stack>
                    </Item>
                </Stack>
            </form>
            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                open={open}
                onClose={handleClose}
                autoHideDuration={4000}
            >
                <Alert severity={(message["status"]) ? "success" : "error"} onClose={handleClose}>
                    {
                        (!_.isEmpty(message)) ? message.message : null
                    }
                </Alert>
            </Snackbar>
        </Box>
    );
}
export default AddStock;