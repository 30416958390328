import {
    Button,
    TextField,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from "@mui/material";
import { Formik, Form, Field } from "formik";
import * as Yup from 'yup';
import axios from "axios";

const validationSchema = Yup.object().shape({
    name: Yup.string('Enter valid input').required('Name is required'),
    description: Yup.string('special charactors are not allowed')
});

const AddFeature = ({ open, handleClose, handleSnakbarOpen, setMessage }) => {
    const postData = async (data) => {
        const response = await axios.post('/features', data);
        const result = await response.data;
        setMessage(result);
        handleClose();
    }

    const handleSubmit = (values) => {
        postData(values);
        handleClose();
    }

    return (
        <div>
            <Dialog open={open} onClose={handleClose}>
                <Formik
                    initialValues={{ name: '', description: '' }}
                    validationSchema={validationSchema}
                    validateOnChange={false}
                    validateOnBlur={false}
                    onSubmit={handleSubmit}
                >
                    {({ values, errors, touched, handleChange }) => (
                        <Form>
                            <DialogTitle>New Feature</DialogTitle>
                            <DialogContent>
                                <DialogContentText>
                                    Add new feature in feature list, this data will be used while creating new product.
                                    Device model will have features.
                                </DialogContentText>
                                <Field
                                    as={TextField}
                                    autoFocus
                                    fullWidth
                                    margin="normal"
                                    variant="standard"
                                    id="name"
                                    name="name"
                                    label="Name"
                                    value={values.name}
                                    onChange={handleChange}
                                    error={touched.name && Boolean(errors.name)}
                                    helperText={touched.name && errors.name}
                                />
                                <Field
                                    as={TextField}
                                    autoFocus
                                    fullWidth
                                    margin="normal"
                                    variant="standard"
                                    id="description"
                                    name="description"
                                    label="Dsescription"
                                    value={values.description}
                                    error={touched.description && Boolean(errors.description)}
                                    helperText={touched.description && errors.description}
                                />
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleClose}>Cancel</Button>
                                <Button type="submit">Add</Button>
                            </DialogActions>
                        </Form>
                    )}
                </Formik>
            </Dialog>
        </div>
    );
}
export default AddFeature;