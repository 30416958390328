import * as React from 'react';
// Routing 
import { Routes, Route, useLocation, useNavigate, Navigate } from "react-router-dom";
import { createTheme, ThemeProvider } from '@mui/material';
// Components
import MainDashboard from './pages/Dashboard/Main.page';
// import Login from './pages/Login/Login.page';
import Login from './pages/Forms/Login2.page';
import indianOcean from './assets/main.theme';
import _ from 'lodash';
import { useSelector } from 'react-redux';

const theme = createTheme(indianOcean);

function App() {

  // let isAuthenticated = false; 
  let isAuthenticated = useSelector(state => state.authenticate.isAuth);
  let currentLocation = useLocation();
  let from = _.get(currentLocation, "state.from.pathname", "/");
  return (
    <ThemeProvider theme={theme}>
      <div>
        <Routes>
          <Route
            path="/*"
            element={isAuthenticated ? <MainDashboard /> : <Navigate to="/login" state={{ from: currentLocation }} replace />}
          />
          <Route
            path="/login"
            element={!isAuthenticated ? <Login /> : <Navigate to={from} replace />}
          />
        </Routes>
      </div>
    </ThemeProvider>
  );
}

export default App;
