import { useState, useEffect } from "react";
import _ from "lodash";
import axios from "axios";
import dayjs from "dayjs";
import localizedFormat from 'dayjs/plugin/localizedFormat';
// MUI Component
import {
    Box, Typography, Grid, Button, IconButton, Snackbar, Alert,

} from "@mui/material";
// MUI icons
import AddIcon from "@mui/icons-material/Add";
import SyncIcon from '@mui/icons-material/Sync';
import DeleteIcon from "@mui/icons-material/Delete";
import FilterAltIcon from '@mui/icons-material/FilterAlt';

// Custom Components
import AddInterface from "../components/Interfaces/AddInterface.component";
import Search from "../components/Search/Search.component";
import Loading from "../components/Common/Loading.component";
import DataTable from "../components/DataTable/DataTable.component";

const columns = [
    {
        name: "Name",
        selector: row => row['name'],
        sortable: true,
    },
    {
        name: "Description",
        selector: row => row['description'],
        wrap: true,
        sortable: true,
    },
    {
        name: "Created On",
        selector: row => dayjs(row.meta.createdAt).format('lll'),
        right: true,
        sortable: true,
    }
];
const title = <Typography variant='h6'>{'Common Interface List'}</Typography>;
const actions = ({ handleOpen, handleChange, getData }) => (
    <Grid container
        alignItems="flex-start"
        justifyContent="flex-end"
        spacing={1}
    >
        <Grid item xs={12} sm={12} md={"auto"} lg={"auto"}>
            <IconButton aria-label="refresh" onClick={getData}>
                <SyncIcon />
            </IconButton>
        </Grid>
        <Grid item xs={12} sm={12} md={"auto"} lg={"auto"}>
            <Search id={"search-distributor"} placeholder={"Search..."} handleChange={handleChange} />
        </Grid>
        <Grid item xs={12} sm={12} md={"auto"} lg={"auto"}>
            <Button
                variant="contained"
                size="small"
                sx={{ float: 'right' }}
                startIcon={<AddIcon />}
                onClick={handleOpen}
            >Add</Button>
        </Grid>
        <Grid item xs={12} sm={12} md={"auto"} lg={"auto"}>
            <Button disabled variant="contained" size="small" sx={{ float: 'right' }} endIcon={<FilterAltIcon />}>Filter</Button>
        </Grid>
    </Grid>
);
const contextActions = deleteHandler => (
    <IconButton onClick={deleteHandler}>
        <DeleteIcon />
    </IconButton>
);
function SearchQuery(queryString, data) {
    const query = queryString.toLowerCase();
    const filteredData = data.filter((d) => {
        const n = d.name.toLowerCase();
        const des = d.description.toLowerCase();
        return n.includes(query) || des.includes(query);
    }, data);
    return filteredData;
}

const InterfacesSection = () => {
    dayjs.extend(localizedFormat);
    const [loading, setLoading] = useState(false);
    const [selectedRows, setSelectedRows] = useState([]);
    const [data, setData] = useState([]);
    const [toggleCleared, setToggleCleared] = useState(false);
    const [queryInput, setQueryInput] = useState('');
    const [open, setOpen] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [message, setMessage] = useState({});

    const getData = async () => {
        setLoading(true);
        const response = await axios.get('/interfaces');
        const result = await response.data;
        setData(result.data);
    }
    const handleChange = (s) => {
        setSelectedRows(s.selectedRows);
    }
    const deleteAll = () => {
        const rows = selectedRows.map(r => r.name);
        if (window.confirm(`Are you sure you want to delete?`)) {
            setToggleCleared(!toggleCleared);
            setData(_.differenceBy(data, rows, 'name'));
        }
    }
    const handleQueryInput = (event) => {
        let query = event.target.value.trim().toLowerCase();
        setQueryInput(query);
    }
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const handleSnakbarClose = () => setSnackbarOpen(false);
    const handleSnakbarOpen = () => setSnackbarOpen(true);
    // mounting
    useEffect(() => {
        getData();
    }, []);
    useEffect(() => {
        if (!_.isEmpty(data)) setLoading(false);
    }, [data])
    let timeoutId = null;
    useEffect(() => {
        // updating
        if (open === false) {
            timeoutId = setTimeout(getData, 1000);
            if (!_.isEmpty(message)) handleSnakbarOpen();
        }
        // unmounting
        return () => {
            if (timeoutId !== null) clearTimeout(timeoutId);
        }
    }, [open]);
    return (
        <Box sx={{ my: 5 }}>
            <Box sx={{ flexGrow: 1, my: 2 }}>
                <DataTable
                    title={title}
                    columns={columns}
                    data={SearchQuery(queryInput, data)}
                    defaultSortField="name"
                    defaultSortFieldId={1}
                    actions={actions({ handleOpen, handleChange: handleQueryInput, getData })}
                    contextActions={contextActions(deleteAll)}
                    onSelectedRowsChange={handleChange}
                    selectableRowsNoSelectAll
                    clearSelectedRows={toggleCleared}
                    // onRowClicked={handleRowClicked}
                    progressPending={loading}
                />
            </Box>
            <AddInterface open={open} handleClose={handleClose} handleSnakbarOpen={handleSnakbarOpen} setMessage={setMessage} />
            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                open={snackbarOpen}
                onClose={handleSnakbarClose}
                autoHideDuration={4000}
            >
                <Alert severity={(message.status) ? "success" : "error"} onClose={handleSnakbarClose} sx={{ width: '100%' }}>
                    {
                        message.message ? message.message : null
                    }
                </Alert>
            </Snackbar>
        </Box>
    );
};

export default InterfacesSection;