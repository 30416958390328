import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
// React Redux
import { BrowserRouter as Router } from 'react-router-dom';

// Redux
import { Provider } from 'react-redux';
import thunk from "redux-thunk";
import { applyMiddleware } from 'redux';
import { composeWithDevTools } from '@redux-devtools/extension';
import interceptor from "./helper/interceptors"
import reducers from "./store/main.store";
import { configureStore } from '@reduxjs/toolkit';
const store = configureStore({
  reducer: reducers
},
  composeWithDevTools(applyMiddleware(thunk))
);
interceptor(store);
// Root Element
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <Router>
      <App />
    </Router>
  </Provider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
