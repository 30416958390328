import * as React from "react";

import { Routes, Route } from "react-router-dom";

// User Components
import UserList from "./List.page";
import AddUser from "../Forms/Add.page";
const Users = props => (
    <Routes>
        <Route path="/add" element={<AddUser />} />
        <Route path="/" element={<UserList />} />
        <Route path="/:id" element={<h5>User Profile Section</h5>} />
    </Routes>
);

export default Users;