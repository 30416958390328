
import { Box, } from "@mui/material";

// custom components
import SectionHeader from "../../components/SectionHeader/SectionHeader.component";
// import Devices from "../../sections/Devices.section";
const Overiview = () => {
    return (
        <Box>
            <SectionHeader heading="Overview" />
            <Box>
                {/* <Devices /> */}
                <h3>Overview page</h3>
            </Box>
        </Box>
    );
}
export default Overiview;