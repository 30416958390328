import {
    onAuthRequest, onAuthSuccess, onAuthFailure, onLogout
} from "../store/actions/authentication.action";
import { login, logout } from "./authenticate.service";
export const loginUser = (data) => async (dispatch) => {
    dispatch(onAuthRequest());
    await login(data['email'], data['password'],
        (response) => {
            dispatch(onAuthSuccess(response));
        },
        (error) => {
            dispatch(onAuthFailure(error));
        }
    );
}

export const logoutUser = () => async (dispatch) => {
    logout();
    dispatch(onLogout());
}
