import { useState, useEffect } from "react";
// Utility
import _ from "lodash";
import * as Yup from 'yup';
import axios from 'axios';
import { useFormik } from "formik";
import { getDefaultTimezone } from "../../helper/utility";
import { useNavigate } from "react-router-dom";
// MUI Component
import { Box, Container, Stack, MenuItem, TextField, Snackbar, Alert } from "@mui/material";
import { styled } from "@mui/material";
// Material Icons
import LoadingButton from '@mui/lab/LoadingButton';
import AddUserIcon from "../../assets/AddUser.svg"
// Section Header
import SectionHeader from "../../components/SectionHeader/SectionHeader.component";



// Styles objects
// const pageTitle = {
//     color: "#707070", mb: 1, p: 1
// }
// const outerBox = {
//     m: 0,
//     p: 1,
//     display: 'flex',
//     flexDirection: 'row',
//     flexWrap: 'wrap'
// }
// const innerBox = {
//     mx: 2,
//     my: 1,
//     p: 0,
// }

const Item = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

const schemaValidation = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    email: Yup.string().email('Invalid email').required('Email is required'),
    password: Yup.string()
        .min(8, 'Password must be at least 8 characters')
        .matches(/[a-z]/, 'Password must contain at least one lowercase letter')
        .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
        .matches(/[0-9]/, 'Password must contain at least one numeric character')
        .required('Password is required'),
    confirmPassword: Yup.string()
        .oneOf([Yup.ref('password'), null], 'Passwords must match')
        .required('Confirm Password is required'),
})

export default function AddUser(props) {
    const initialValues = {
        role: Yup.string().ensure().uuid('Invalid role').required('Role is required'),
        name: '',
        email: '',
        password: '',
        confirmPassword: ''
    };
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState('');
    const [roles, setRoles] = useState([]);
    const handleClose = () => {
        setOpen(false);

    }

    const getRoles = async () => {
        const response = await axios.get('/roles/getRolesListType2');
        const result = await response.data;
        setRoles(result.data);
    }

    const postData = async (data) => {
        const response = await axios.post('/users', {
            roleId: data.role,
            name: data.name,
            email: data.email,
            password: data.password,
            zone: getDefaultTimezone()
        });
        const result = await response.data;
        setMessage(result)
        setOpen(true);
        setLoading(false);
    }

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: schemaValidation,
        onSubmit: (values) => {
            setLoading(true);
            postData(values);
        }
    });

    useEffect(() => {
        getRoles();
    }, [])

    useEffect(() => {
        if (!open && !_.isEmpty(message) && message.status) {
            navigate("/users", { replace: true });
        }
    }, [open]);

    return (
        <Box>
            <SectionHeader heading="Add New User" />
            <Box sx={{ flexGrow: 1 }}>
                <form onSubmit={formik.handleSubmit}>
                    <Stack
                        direction="row"
                        justifyContent="space-evenly"
                        alignItems="flex-start"
                    >
                        <Item>
                            <Container maxWidth="lg">
                                <Stack
                                    sx={{ mt: 10 }}
                                    direction="column"
                                    justifyContent="flex-start"
                                    alignItems="stretch"
                                // spacing={2}
                                >
                                    <Item>
                                        <img src={AddUserIcon} width="100px" height="auto" alt="Add_user_icon" />
                                    </Item>
                                    <Item>
                                        <TextField
                                            fullWidth
                                            select
                                            size="small"
                                            id="role"
                                            label="Select Role"
                                            variant='outlined'
                                            name="role"
                                            value={formik.values.role}
                                            onChange={formik.handleChange}
                                            error={formik.touched.role && Boolean(formik.errors.role)}
                                            helperText={formik.touched.role && formik.errors.role}
                                        >
                                            {
                                                roles.map((role) => (
                                                    <MenuItem key={role._docId} value={role._docId}>
                                                        {role.name}
                                                    </MenuItem>
                                                ))
                                            }
                                        </TextField>
                                    </Item>
                                    <Item>
                                        <TextField
                                            type="text"
                                            size="small"
                                            id="name"
                                            label="Name"
                                            variant='outlined'
                                            value={formik.values.name}
                                            onChange={formik.handleChange}
                                            error={formik.touched.name && Boolean(formik.errors.name)}
                                            helperText={formik.touched.name && formik.errors.name}
                                        />
                                    </Item>
                                    <Item>
                                        <TextField
                                            type="email"
                                            size="small"
                                            id="email"
                                            label="Email"
                                            variant='outlined'
                                            value={formik.values.email}
                                            onChange={formik.handleChange}
                                            error={formik.touched.email && Boolean(formik.errors.email)}
                                            helperText={formik.touched.email && formik.errors.email}
                                        />
                                    </Item>
                                    <Item>
                                        <TextField
                                            type="password"
                                            size="small"
                                            id="password"
                                            label="Password"
                                            variant='outlined'
                                            value={formik.values.password}
                                            onChange={formik.handleChange}
                                            error={formik.touched.password && Boolean(formik.errors.password)}
                                            helperText={formik.touched.password && formik.errors.password}
                                        />
                                    </Item>
                                    <Item>
                                        <TextField
                                            type="password"
                                            size="small"
                                            id="confirmPassword"
                                            label="Confirm Password"
                                            name='confirmPassword'
                                            variant='outlined'
                                            value={formik.values.confirmPassword}
                                            onChange={formik.handleChange}
                                            error={formik.touched.confirmPassword && Boolean(formik.errors.confirmPassword)}
                                            helperText={formik.touched.confirmPassword && formik.errors.confirmPassword}
                                        />
                                    </Item>
                                    {/* Submit Button */}
                                    <Item>
                                        <LoadingButton
                                            variant="contained"
                                            type="submit"
                                            loading={loading}
                                        >
                                            Add
                                        </LoadingButton >
                                    </Item>
                                </Stack>
                            </Container>
                        </Item>
                    </Stack>
                </form>

                <Snackbar
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    open={open}
                    onClose={handleClose}
                // autoHideDuration={4000}
                >
                    <Alert severity={message.status ? "success" : "error"} onClose={handleClose}>
                        {
                            (_.isEmpty(message)) ? null : message.message
                        }
                    </Alert>
                </Snackbar>
            </Box>
        </Box>
    );
}