import { useState, useCallback, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import _ from "lodash";
import Excel from "exceljs";
import axios from 'axios';
import DemoFile from "../../assets/demo_serials.xlsx";
import AddBulkStockIllustration from "../../assets/AddBulkStock.svg";
// Mui Components
import {
    Box, Container, Alert, Backdrop, CircularProgress, Link, FormHelperText, Stack,
    Button, Divider, TextField, MenuItem, Typography, TableContainer, Snackbar,
    Table, TableHead, TableRow, TableCell, TableBody, TableFooter, TablePagination
} from "@mui/material";
import { styled } from "@mui/material";
// Custom Components
import SectionHeader from "../../components/SectionHeader/SectionHeader.component";
import TablePaginationActions from '../../components/Table/TablePaginationActions.component';


const Item = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));
const validateSerial = (s) => {
    const pattern = /^([A-Za-z0-9]{4}-){3}[A-Za-z0-9]{4}$/
    return pattern.test(s);
}

// const CircularProgressWithLabel = (props) => (
//     <Box sx={{ position: 'relative', display: 'inline-flex' }}>
//         <CircularProgress variant="determinate" {...props} color="inherit" />
//         <Box
//             sx={{
//                 top: 0,
//                 left: 0,
//                 bottom: 0,
//                 right: 0,
//                 position: 'absolute',
//                 display: 'flex',
//                 alignItems: 'center',
//                 justifyContent: 'center',
//             }}
//         >
//             <Typography variant="caption" component="div" color="white" >
//                 {`${Math.round(props.value)}`}
//             </Typography>
//         </Box>
//     </Box>
// )

const ShowData = ({ data }) => {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;

    const handleChangePage = (event, newPage,) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    return (
        <Box sx={{ flexGrow: 1, py: 2, mt: 2 }}>

            {
                (data.some(d => d.isValid === false)) ?
                    <Alert severity="warning" align="left">
                        {/* <AlertTitle>Note</AlertTitle> */}
                        {'Invalid serial-ids will not be submitted, so modify it in your excel file before submission.'}
                    </Alert> : null
            }
            <TableContainer>
                <Table size="small" aria-label="custom pagination table and small">
                    <TableHead>
                        <TableRow>
                            <TableCell align="left">Serial</TableCell>
                            <TableCell align="left">Validation</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {(rowsPerPage > 0
                            ? [...data.filter(a => a.isValid === false), ...data.filter(a => a.isValid === true)].slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            : data
                        ).map((row) => (
                            <TableRow key={row['serial']}>

                                <TableCell align="left">
                                    {row["serial"]}
                                </TableCell>
                                <TableCell align="left" >
                                    {(row["isValid"]) ? "Valid" : "Invalid"}
                                </TableCell>
                            </TableRow>
                        ))}
                        {emptyRows > 0 && (
                            <TableRow style={{ height: 53 * emptyRows }}>
                                <TableCell colSpan={6} />
                            </TableRow>
                        )}
                    </TableBody>
                    <TableFooter>
                        <TableRow>

                            <TablePagination
                                rowsPerPageOptions={[10, 20, 50, { label: 'All', value: -1 }]}
                                // colSpan={3}
                                count={data.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                SelectProps={{
                                    inputProps: {
                                        'aria-label': 'rows per page',
                                    },
                                    native: true,
                                }}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                ActionsComponent={TablePaginationActions}
                            />
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer >
            <Container maxWidth="sm" sx={{ mt: 3, textAlign: 'center' }}>

            </Container>
        </Box>

    )
}

const AddBulkStock = () => {
    const [modelList, setModelList] = useState([]);
    const [model, setModel] = useState("");
    const [serialList, setSerialList] = useState({})
    const [show, setShow] = useState(false);
    const [progress, setProgress] = useState(0);
    const [openBackdrop, setOpenBackdrop] = useState(false);
    const [openSnackBar, setOpenSnackBar] = useState(false);
    const [fileError, setFileError] = useState('');
    const [modelError, setModelError] = useState('');
    const [message, setMessage] = useState({});

    // const checkSerials = async (data)=>{
    //     const response = await axios.get('/warehouse/stock/checkSerials', {
    //         "serials": data
    //     });
    //     const result = await response.data;
    //     return result;
    // }
    const getModels = async () => {
        const response = await axios.get('/models/getModelListType2');
        const result = await response.data;
        setModelList(result.data);
    }
    const postData = async () => {
        if (model === "") {
            setModelError('Model not selected');
            return;
        }
        if (_.isEmpty(serialList)) {
            setFileError('File not selected');
            return;
        }
        // Need to create provision for large datasets 
        // For large datasets a sequence of requests will be made and datasets will be splits in chunks
        // This can take payload upto 1000 serial strings
        const response = await axios.post('/warehouse/stock/createStock', {
            "model": model,
            "serials": serialList.map(s => { if (s.isValid) return s.serial })
        });
        const result = await response.data;
        setMessage(result);
        setOpenSnackBar(true);
    }
    const handleClose = () => setOpenBackdrop(false);
    const handleCloseSnackBar = () => {
        setOpenSnackBar(false);
    }
    useEffect(() => {
        getModels();
    }, []);

    useEffect(() => {
        if (model !== "") setModelError("");
    }, [model]);

    const onDrop = useCallback((acceptedFiles) => {
        setFileError("");
        const file = acceptedFiles[0];
        const wb = new Excel.Workbook();
        const reader = new FileReader();
        const d = [];
        reader.readAsArrayBuffer(file);
        reader.onload = () => {
            setOpenBackdrop(true);
            const buffer = reader.result;
            wb.xlsx.load(buffer).then((workbook) => {
                const sheet = workbook.getWorksheet("Sheet");
                const rows = sheet.getColumn(1);
                const rowsCount = rows['_worksheet']['_rows'].length;
                let count = 1;
                sheet.eachRow((row, rowIndex) => {
                    if (row.values[1] !== "Serial") {
                        d.push(row.values[1]);
                        setProgress(Number((count / (rowsCount - 1)) * 100));
                        count++;
                    }
                });
                setSerialList(d.map(s => {
                    return {
                        "serial": s,
                        "isValid": validateSerial(s),
                        // "exists": prelist.includes(s)
                    }
                }));
                handleClose();
                setShow(true);
            });
        };
    }, []);

    const { getRootProps, getInputProps } = useDropzone({
        onDrop, accept: {
            "application/vnd.ms-excel": ['.xls'],
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [".xlsx"]
        }
    });

    return (
        <Box>
            {/* <SectionHeader heading='Add Bulk Stock' /> */}
            <Box sx={{ flexGrow: 1 }}>
                <Stack
                    direction="row"
                    divider={<Divider orientation="vertical" flexItem />}
                    justifyContent="space-evenly"
                    alignItems="flex-start"
                    spacing={2}
                >

                    {
                        show ?
                            <Item>
                                <ShowData data={serialList} />
                            </Item>
                            : null
                    }
                    <Item>
                        <Container maxWidth="lg">
                            <Stack
                                sx={{ mt: 10 }}
                                direction="column"
                                justifyContent="flex-start"
                                alignItems="stretch"
                                spacing={3}
                            // divider={<Divider orientation="horizontal" flexItem />}
                            >
                                <Item>
                                    <img src={AddBulkStockIllustration} width="100px" height="auto" alt='Add_bulk_stock_icon' />
                                </Item>
                                {/* <Item>
                                <Typography variant="h5" align="center" gutterBottom>
                                    Select Model and drop excel file
                                </Typography>
                            </Item> */}
                                <Item>
                                    <Alert severity="info" align="left" gutterBottom>
                                        {/* <AlertTitle>Remember</AlertTitle> */}
                                        More than few hundred serials will take some time to upload.
                                    </Alert>
                                </Item>
                                <Item>
                                    {/* <Container maxWidth="sm"> */}
                                    <TextField
                                        fullWidth
                                        size="small"
                                        select
                                        label="Select Model"
                                        id='model'
                                        name='model'
                                        value={model}
                                        onChange={(event) => setModel(event.target.value)}
                                        error={(modelError !== "") ? true : false}
                                        helperText={(modelError !== "") ? modelError : null}
                                    >
                                        {
                                            modelList.map((model) => (
                                                <MenuItem key={model._docId} value={model._docId}>
                                                    {model.model}
                                                </MenuItem>
                                            ))
                                        }
                                    </TextField>
                                    {/* </Container> */}
                                </Item>
                                <Item>
                                    {/* <Container maxWidth="sm"> */}
                                    <Box>
                                        <Box {...getRootProps()} style={{
                                            border: '2px dashed #ccc',
                                            borderRadius: '5px',
                                            padding: '2rem',
                                            textAlign: 'center'
                                        }}>
                                            <input type="file"   {...getInputProps()} />
                                            <Typography variant="body1" align="center" gutterBottom>
                                                Drag and drop files here, or click to select files
                                            </Typography>
                                        </Box>
                                        {
                                            (fileError !== "") ?
                                                <FormHelperText error>{"File not selected"}</FormHelperText>
                                                : null
                                        }
                                        <Box sx={{ mt: 2, float: 'left' }}>
                                            <Link href={DemoFile} target='_self' download>Download demo excel file</Link>
                                        </Box>
                                    </Box>
                                    {/* </Container> */}
                                </Item>

                                <Item>
                                    <Button variant='contained' onClick={postData}>
                                        Upload
                                    </Button>
                                </Item>
                            </Stack>
                        </Container>
                    </Item>

                </Stack>
            </Box>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={openBackdrop}
                onClick={handleClose}
            >
                {/* <Box sx={{ width: '100%' }}> */}
                <CircularProgress variant="determinate" value={progress} />
                {/* </Box> */}
            </Backdrop>
            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                open={openSnackBar}
                onClose={handleCloseSnackBar}
                autoHideDuration={4000}
            >
                <Alert severity={(message["status"]) ? "success" : "error"} onClose={handleCloseSnackBar}>
                    {
                        (!_.isEmpty(message)) ? message.message : null
                    }
                </Alert>
            </Snackbar>
        </Box>

    );
}
export default AddBulkStock;

