import _ from 'lodash';
import { createReducer } from '@reduxjs/toolkit';
import { setTimeZone } from '../../helper/utility';
import { userPool } from "../../services/authenticate.service";
import {
    onAuthFailure,
    onAuthRequest,
    onAuthSuccess,
    onLogout
} from "../actions/authentication.action";

const INITIAL_STATE = {
    isloading: false,
    authUser: {},
    error: null,
    isAuth: false,
}
// CHECK LOCALSTORAGE
const currentUser = userPool.getCurrentUser();
if (currentUser) {
    currentUser.getSession((error, session) => {
        if (error) {
            localStorage.clear();
            INITIAL_STATE.error = error;
        } else {
            INITIAL_STATE.isAuth = true;
            INITIAL_STATE.authUser = session;
            setTimeZone(session['idToken']['payload']['zoneinfo']);
        }
    });
}

// AUTHENTICATION REDUCER
const authReducer = createReducer(INITIAL_STATE, (builder) => {
    builder
        .addCase(onAuthRequest, (state) => {
            state.isloading = true;
            state.error = null;
        })
        .addCase(onAuthSuccess, (state, action) => {
            state.isloading = false;
            state.isAuth = true;
            state.authUser = action.payload;
            // setTimeZone(data['idToken']['payload']['zoneinfo']);
        })
        .addCase(onAuthFailure, (state, action) => {
            state.isloading = false;
            state.isAuth = false;
            state.authUser = {};
            state.error = action.payload;
        })
        .addCase(onLogout, (state) => {
            state.isloading = false;
            state.isAuth = false;
            state.authUser = null;
            state.error = null;
        })
});

export default authReducer;