import { useState } from "react";
import axios from "axios";
// Mui Components
import {
    Box, Paper, Grid, Button, IconButton,
    Table, TableBody, TableCell, TableContainer,
    TableHead, TableRow, TableFooter, TablePagination,
} from "@mui/material";
// Mui Icons
import SyncIcon from '@mui/icons-material/Sync';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
// Custom components
import Search from "../Search/Search.component";
import TablePaginationActions from "../Table/TablePaginationActions.component";
function SearchQuery(data, queryString) {
    const query = queryString.toLowerCase();
    const filteredData = data.filter((d) => {
        const n = d.name.toLowerCase();
        const e = d.email.toLowerCase();
        return n.includes(query) || e.includes(query);
    }, data);
    return filteredData
}
const InvitatedUsers = () => {
    const [queryInput, setQueryInput] = useState('');
    const [rows, setRows] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    const handleSearch = (e) => {
        const v = e.target.value.toLowerCase();
        setQueryInput(v);
    }
    const getData = async () => {
        const response = await axios.get('/users/pendingInvites');
        const result = await response.data;
        if (result.status) setRows(result.data);
    }

    return (
        <Box>
            <Box sx={{ flexGrow: 1, my: 2 }}>
                <Grid container
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                    spacing={1}>

                    <Grid item xs={12} sm={12} md={"auto"} lg={"auto"}>
                        {/* <Button variant="contained" onClick={() => getUsers("")}>Refresh
                                </Button> */}
                        <IconButton aria-label="refresh" onClick={getData}>
                            <SyncIcon />
                        </IconButton>
                    </Grid>
                    <Grid item xs={12} sm={12} md={"auto"} lg={"auto"}>
                        <Search id="user-search" handleChange={handleSearch} placeholder={"Search..."} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={"auto"} lg={"auto"}>
                        <Button disabled variant="contained" size="small" endIcon={<FilterAltIcon />}>Filter</Button>
                        {/* <Button variant="contained" size="small" endIcon={<FilterAltIcon />}>Filter</Button> */}
                    </Grid>
                </Grid>
            </Box>
            <TableContainer component={Paper}>
                <Table aria-label="custom pagination table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="left">Name</TableCell>
                            <TableCell align="left">Email</TableCell>

                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {(rowsPerPage > 0
                            ? SearchQuery(rows, queryInput).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            : SearchQuery(rows, queryInput)
                        ).map((row) => (
                            <TableRow key={row._docId}>
                                <TableCell align="left">
                                    {row.name}
                                </TableCell>
                                <TableCell align="left">
                                    {row.email}
                                </TableCell>
                            </TableRow>
                        ))}
                        {emptyRows > 0 && (
                            <TableRow style={{ height: 53 * emptyRows }}>
                                <TableCell colSpan={2} />
                            </TableRow>
                        )}
                        {
                            (SearchQuery(rows, queryInput).length === 0) ?
                                <TableRow>
                                    <TableCell colSpan={2} align="center">
                                        {'No data found'}
                                    </TableCell>
                                </TableRow>
                                : null
                        }
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                                colSpan={3}
                                count={rows.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                SelectProps={{
                                    inputProps: {
                                        'aria-label': 'rows per page',
                                    },
                                    native: true,
                                }}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                ActionsComponent={TablePaginationActions}
                            />
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
        </Box>
    );
}
export default InvitatedUsers;