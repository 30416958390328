import * as React from "react";
import { useNavigate } from "react-router-dom";

import {
    List, Divider,
} from "@mui/material";

// Material icon components
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';

// Nav components
import { DashoardItems, MangementItems } from './NavItems.component';


// Component Function
export default function NavList(props) {
    const navigate = useNavigate();
    return (
        <>
            <List>
                {
                    DashoardItems.map((text, index) => (
                        <ListItem
                            key={text.id}
                            disablePadding sx={{ display: 'block' }}
                            onClick={() => navigate(text.path)}
                        >
                            <ListItemButton
                                sx={{
                                    minHeight: 48,
                                    justifyContent: props.open ? 'initial' : 'center',
                                    px: 2.5,
                                }}
                            >
                                <ListItemIcon
                                    sx={{
                                        minWidth: 0,
                                        mr: props.open ? 3 : 'auto',
                                        justifyContent: 'center',
                                    }}
                                >
                                    {text.icon}
                                </ListItemIcon>
                                <ListItemText primary={text.title} sx={{ opacity: props.open ? 1 : 0 }}
                                    primaryTypographyProps={{
                                        // variant: 'subtitle2',
                                        style: {
                                            whiteSpace: 'nowrap',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis'
                                        }
                                    }}
                                />
                            </ListItemButton>
                        </ListItem>
                    ))
                }
            </List>
            <Divider />
            <List>
                {
                    MangementItems.map((text, index) => (
                        <ListItem
                            key={text.id}
                            disablePadding sx={{ display: 'block' }}
                            onClick={() => navigate(text.path)}
                        >
                            <ListItemButton
                                sx={{
                                    minHeight: 48,
                                    justifyContent: props.open ? 'initial' : 'center',
                                    px: 2.5,
                                }}
                            >
                                <ListItemIcon
                                    sx={{
                                        minWidth: 0,
                                        mr: props.open ? 3 : 'auto',
                                        justifyContent: 'center',
                                    }}
                                >
                                    {text.icon}
                                </ListItemIcon>
                                <ListItemText primary={text.title} sx={{ opacity: props.open ? 1 : 0 }}
                                    primaryTypographyProps={{
                                        // variant: 'subtitle2',
                                        style: {
                                            whiteSpace: 'nowrap',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis'
                                        }
                                    }}
                                />
                            </ListItemButton>
                        </ListItem>
                    ))}
            </List>
        </>
    );
}