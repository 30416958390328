import { useState, useEffect } from "react";

// MUI Components
import { useTheme } from '@mui/material/styles';
import { green, pink, deepPurple, amber } from '@mui/material/colors';
import { Box, Typography, Grid, Stack, Card, CardHeader, CardContent } from "@mui/material";
// Mui Icons
import DownloadDoneIcon from '@mui/icons-material/DownloadDone';
import WarehouseIcon from '@mui/icons-material/Warehouse';
import EventSeatIcon from '@mui/icons-material/EventSeat';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
// Custom Components
import SectionHeader from "../../components/SectionHeader/SectionHeader.component";
import { BoxItem, PaperItem } from "../../components/Common/Item.component";
import CountCard from "../../components/Cards/StageCount.component";
import StageOriented from "../../layouts/Sales/StageOriented.layout";
import ModelOriented from "../../layouts/Sales/ModelOriented.layout";
import ModelWise from '../../layouts/Sales/ModelWise.layout';
import MonthlySales from '../../layouts/Sales/MonthlySales.layout';
const Sales = () => {
    const theme = useTheme();
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState({});

    return (
        <Box>
            <SectionHeader heading="Sales" />
            <Box sx={{ mt: 2 }}>
                <Grid
                    container
                    direction="row"
                    justifyContent="space-around"
                    alignItems="baseline"
                    spacing={2}
                >
                    <Grid item lg={3}>
                        <PaperItem>
                            <CountCard title="Installed" count="75" icon={<DownloadDoneIcon />} bgcolor={{ bgcolor: green[500] }} />
                        </PaperItem>
                    </Grid>
                    <Grid item lg={3}>
                        <PaperItem>
                            <CountCard title="Store" count="581" icon={<WarehouseIcon />} bgcolor={{ bgcolor: deepPurple[500] }} />
                        </PaperItem>
                    </Grid>
                    <Grid item lg={3}>
                        <PaperItem>
                            <CountCard title="Shipping" count="71" icon={<LocalShippingIcon />} bgcolor={{ bgcolor: pink[500] }} />
                        </PaperItem>
                    </Grid>
                    <Grid item lg={3}>
                        <PaperItem>
                            <CountCard title="Reserved" count="82" icon={<EventSeatIcon />} bgcolor={{ bgcolor: amber[600] }} />
                        </PaperItem>
                    </Grid>
                    <Grid item lg={8}>
                        <ModelWise
                            title="Top Companies"
                            subheader="(+43%) than last year"
                            chartData={[
                                { label: 'Aryom Technologies', value: 400 },
                                { label: 'Tata Power', value: 430 },
                                { label: 'Tata Motors', value: 448 },
                                { label: 'Adani Windmar', value: 470 },
                                { label: 'Reliance Petroleum', value: 540 },
                                { label: 'Boing', value: 580 },
                                { label: 'Airbus', value: 690 },
                                { label: 'John Deer', value: 1100 },
                                { label: 'CocaCola', value: 1200 },
                                { label: 'Irctc', value: 1380 },
                            ]}
                        />
                    </Grid>
                    <Grid item lg={4}>
                        <ModelOriented
                            title="Regional"
                            chartData={[
                                { label: 'America', value: 4344 },
                                { label: 'Asia', value: 5435 },
                                { label: 'Europe', value: 1443 },
                                { label: 'Africa', value: 4443 },
                            ]}
                            chartColors={[
                                theme.palette.primary.main,
                                theme.palette.info.main,
                                theme.palette.warning.main,
                                theme.palette.error.main,
                            ]}
                        />
                    </Grid>
                    <Grid item lg={8}>
                        <MonthlySales
                            title="Monthly Sales"
                            subheader="(+43%) than last year"
                            chartLabels={[
                                '11/01/2022',
                                '12/01/2022',
                                '01/01/2023',
                                '02/01/2023',
                                '03/01/2023',
                                '04/01/2023',
                                '05/01/2023',
                                '06/01/2023',
                                '07/01/2023',
                                '08/01/2023',
                                '09/01/2023',
                            ]}
                            chartData={[
                                {
                                    name: 'Profit',
                                    type: 'column',
                                    fill: 'solid',
                                    data: [23, 11, 22, 27, 13, 22, 37, 21, 44, 22, 30],
                                },
                                {
                                    name: 'Purchase',
                                    type: 'area',
                                    fill: 'gradient',
                                    data: [44, 55, 41, 67, 22, 43, 21, 41, 56, 27, 43],
                                },
                                {
                                    name: 'Sales',
                                    type: 'line',
                                    fill: 'solid',
                                    data: [30, 25, 36, 30, 45, 35, 64, 52, 59, 36, 39],
                                },
                            ]}
                        />
                    </Grid>
                    <Grid item lg={4}>
                        <StageOriented
                            title="Stage Oriented"
                            chartLabels={['Store', 'Reserved', 'Shipping', 'Installed', 'Returned', 'Repair', 'Scrapped']}
                            chartData={[
                                { name: 'AG-702', data: [541, 42, 35, 38, 0, 0, 0] },
                                { name: 'AG-1621', data: [40, 40, 36, 37, 0, 0, 0] },

                            ]}
                            chartColors={[...Array(6)].map(() => theme.palette.text.secondary)}
                        />
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
};
export default Sales;