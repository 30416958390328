// Mui Component
import { Stack, Typography, Avatar } from "@mui/material";

// Custom Components
import { BoxItem } from "../Common/Item.component";

const CountCard = ({ icon, bgcolor, count, title }) => (
    <Stack
        direction="row"
        justifyContent="space-around"
        alignItems="center"
        spacing={1}
    >
        <BoxItem>
            <Typography variant="h4">{count}</Typography>
            <Typography variant="body2">{title}</Typography>
        </BoxItem>
        <BoxItem>
            <Avatar sx={{ ...bgcolor, width: 60, height: 60 }}>
                {icon}
            </Avatar>
        </BoxItem>
    </Stack>
);
export default CountCard;