import * as React from 'react';
import { Routes, Route } from "react-router-dom";

import { Box, } from "@mui/material";
// Components
import SectionHeader from '../../components/SectionHeader/SectionHeader.component';
import Stock from '../../layouts/Stock.section';
import Tracking from '../../layouts/Tracking.section';
import Replacements from "../../layouts/Replacement.section";
import AddStock from '../../layouts/AddStock.layout';
import UpdateBulkStage from '../Forms/UpdateBulkStage.page';
const WarehouseComponent = () => (
    <Box>
        <SectionHeader heading="Warehouse" />
        <Stock />
        <Tracking />
        <Replacements />
    </Box>
)
export default function Warehouse(props) {
    return (
        <Routes>
            <Route path="/" element={<WarehouseComponent />} />
            <Route path="/addstock" element={<AddStock />} />
            <Route path="/bulkupdate" element={<UpdateBulkStage />} />
        </Routes>
    )
}