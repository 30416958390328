import { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import _ from "lodash";
import axios from 'axios';
import { useFormik } from "formik";
import { useDropzone } from 'react-dropzone';
import * as Yup from 'yup';
import DataTable from "react-data-table-component";
import DemoFile from '../../assets/demo_serials.xlsx';
import Excel from "exceljs";
// @mui
import {
    Box, Container, Alert, Backdrop, CircularProgress, Link, FormHelperText, Stack, Grid,
    Button, Divider, TextField, MenuItem, Typography, FormControlLabel, FormControl,
    FormLabel, RadioGroup, Radio, Snackbar
} from '@mui/material';
import { styled } from "@mui/material";

// @Material-icons
import EventSeatIcon from '@mui/icons-material/EventSeat'; // 2 Reserved
import LocalShippingIcon from '@mui/icons-material/LocalShipping'; // 3 Shipping
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn'; // 5 Return
import BuildCircleIcon from '@mui/icons-material/BuildCircle'; // 6 Repairing
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'; // 7 Scrap

// Custom Components
import UpdateIllustration from '../../assets/update.svg';
import DataTableBase from '../../components/DataTable/DataTable.component';
import SectionHeader from '../../components/SectionHeader/SectionHeader.component';
import { validateSerial } from '../../helper/utility';
import { BoxItem } from '../../components/Common/Item.component';
const RadioLabel = ({ icon, label }) => (
    <Grid
        container
        direction='column'
        justifyContent='flex-start'
        alignItems='center'
        spacing={0}
    >
        <Grid item sx={{ mx: 1 }}>{icon}</Grid>
        <Grid item sx={{ mx: 1 }}>{label}</Grid>
    </Grid>
)

const columns = [
    {
        name: 'Serial',
        selector: row => row['serial'],
        sortable: true,
    },
    {
        name: 'Validation',
        selector: row => row['isValid'],
        sortable: true,
        cell: row => row['isValid'] ? 'Valid' : 'Invalid'
    }
]

const validationSchema = Yup.object().shape({
    stage: Yup.string().ensure().matches(/^[23567]{1}$/, 'Invalid stage'),
    buyer: Yup.string().ensure().uuid("Invalid Buyer id").required("Buyer is required")
})

const UpdateBulkStage = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [buyerList, setBuyerList] = useState([]);
    const [serialList, setSerialList] = useState([]);
    const [fileError, setFileError] = useState('');
    const [message, setMessage] = useState({});
    const [openSnackBar, setOpenSnackBar] = useState(false);

    const getBuyerList = async () => {
        const response = await axios.get('/distributors/getListType2');
        const result = await response.data;
        setBuyerList(result.data);
    }

    const postData = async (data) => {
        const response = await axios.post('/warehouse/tracking', data);
        const result = await response.data;
        setMessage(result);
        setOpenSnackBar(true);
    }

    const formik = useFormik({
        initialValues: {
            serials: [],
            stage: '',
            buyer: ''
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            values.serials = serialList.map(s => { if (s.isValid) return s.serial });
            postData(values);
        }
    });

    const handleRadioButtons = event => formik.setFieldValue('stage', event.target.value);

    const handleCloseSnackBar = () => {
        setOpenSnackBar(false);
    }

    const onDrop = useCallback(acceptedFiles => {
        setFileError('');
        const file = acceptedFiles[0];
        const wb = new Excel.Workbook();
        const reader = new FileReader();
        const d = [];
        reader.readAsArrayBuffer(file);
        reader.onload = () => {
            setLoading(true);
            const buffer = reader.result;

            wb.xlsx.load(buffer).then((workbook) => {
                const sheet = workbook.getWorksheet("Sheet");
                const rows = sheet.getColumn(1);
                // const rowsCount = rows['_worksheet']['_rows'].length;
                sheet.eachRow((row, rowIndex) => {
                    if (row.values[1] !== "Serial") {
                        d.push(row.values[1]);
                    }
                });
                setSerialList(d.map(s => {
                    return {
                        "serial": s,
                        "isValid": validateSerial(s),
                        // "exists": prelist.includes(s)
                    }
                }));
            });
        }
    }, []);

    const { getRootProps, getInputProps } = useDropzone({
        onDrop, accept: {
            "application/vnd.ms-excel": ['.xls'],
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [".xlsx"]
        }
    });

    useEffect(() => {
        getBuyerList();
        return () => setBuyerList([]);
    }, []);

    useEffect(() => { if (!_.isEmpty(serialList)) setLoading(false) }, [serialList]);
    useEffect(() => {
        if (!openSnackBar && !_.isEmpty(message) && message.status) {
            navigate('/warehouse', { replace: true });
        }
    }, [openSnackBar])
    return (
        <Box>
            <SectionHeader heading='Update Bulk Stage' />
            <Box sx={{ flexGrow: 1 }}>
                <Stack
                    direction="row"
                    divider={<Divider orientation="vertical" flexItem />}
                    justifyContent="space-around"
                    alignItems="flex-start"
                    spacing={0}
                >

                    <BoxItem>
                        <Container maxWidth="lg">
                            <form
                                onSubmit={formik.handleSubmit}
                            >
                                <Stack
                                    sx={{ mt: 5 }}
                                    direction="column"
                                    justifyContent="flex-start"
                                    alignItems="center"
                                    spacing={3}
                                // divider={<Divider orientation="horizontal" flexItem />}
                                >
                                    <BoxItem>
                                        <img src={UpdateIllustration} width="100px" height="auto" alt='Update_icon' />
                                    </BoxItem>
                                    <BoxItem>
                                        <FormControl>
                                            <FormLabel id='stage-label'>Select Stage</FormLabel>
                                            <RadioGroup
                                                row
                                                aria-labelledby='stage-label'
                                                name='stage'
                                                value={formik.values.stage}
                                                onChange={handleRadioButtons}
                                                required
                                            >
                                                <FormControlLabel
                                                    value={'2'}
                                                    control={<Radio size="small" />}
                                                    labelPlacement="top"
                                                    label={<RadioLabel label='Reserved' icon={<EventSeatIcon />} />}
                                                    sx={{ color: '#D9B03B' }}
                                                />
                                                <FormControlLabel
                                                    value={'3'}
                                                    control={<Radio size="small" />}
                                                    labelPlacement="top"
                                                    label={<RadioLabel label='Shipping' icon={<LocalShippingIcon />} />}
                                                    sx={{ color: '#E03997' }}
                                                />
                                                <FormControlLabel
                                                    value={'5'}
                                                    control={<Radio size="small" />}
                                                    labelPlacement="top"
                                                    label={<RadioLabel label='Returned' icon={<KeyboardReturnIcon />} />}
                                                    sx={{ color: '#FBBD08' }}
                                                />
                                                <FormControlLabel
                                                    value={'6'}
                                                    control={<Radio size="small" />}
                                                    labelPlacement="top"
                                                    label={<RadioLabel label='Repairing' icon={<BuildCircleIcon />} />}
                                                    sx={{ color: '#F2711C' }}
                                                />
                                                <FormControlLabel
                                                    value={'7'}
                                                    control={<Radio size="small" />}
                                                    labelPlacement="top"
                                                    label={<RadioLabel label='Scrapped' icon={<DeleteForeverIcon />} />}
                                                    sx={{ color: '#DB445F' }}
                                                />
                                            </RadioGroup>
                                            {
                                                (formik.touched.stage && Boolean(formik.errors.stage)) ?
                                                    <FormHelperText error>{formik.errors.stage}</FormHelperText> : null
                                            }
                                        </FormControl>
                                    </BoxItem>
                                    <BoxItem>
                                        <FormControl
                                            size='small'
                                        // fullWidth
                                        >
                                            <FormLabel component="legend">
                                                {'Select Buyer'}
                                            </FormLabel>
                                            <TextField
                                                size='small'
                                                select
                                                id='buyer'
                                                name='buyer'
                                                value={formik.values.buyer}
                                                onChange={formik.handleChange}
                                                error={formik.touched.buyer && Boolean(formik.errors.buyer)}
                                                helperText={formik.touched.buyer && formik.errors.buyer}
                                            >
                                                {
                                                    buyerList.map(b => (
                                                        <MenuItem key={b._docId} value={b._docId}>
                                                            {b.name}
                                                        </MenuItem>
                                                    ))
                                                }

                                            </TextField>
                                        </FormControl>
                                    </BoxItem>
                                    <BoxItem>
                                        <Box>
                                            <Box {...getRootProps()} style={{
                                                border: '2px dashed #ccc',
                                                borderRadius: '5px',
                                                padding: '2rem',
                                                textAlign: 'center',
                                                cursor: 'pointer'
                                            }}>
                                                <input type="file"   {...getInputProps()} />
                                                <Typography variant="body1" align="center" gutterBottom>
                                                    Drag and drop files here, or click to select files
                                                </Typography>
                                            </Box>
                                            {
                                                (fileError !== "") ?
                                                    <FormHelperText error>{"File not selected"}</FormHelperText>
                                                    : null
                                            }
                                            <Box sx={{ mt: 2, float: 'left' }}>
                                                <Link href={DemoFile} target='_self' download>Download demo excel file</Link>
                                            </Box>
                                        </Box>
                                    </BoxItem>
                                    <BoxItem>
                                        <Button variant='contained' type='submit'>Update</Button>
                                    </BoxItem>
                                </Stack>
                            </form>
                        </Container>
                    </BoxItem>
                    <BoxItem>
                        <Container maxWidth="xs">
                            <DataTable
                                dense
                                pagination
                                // title='Serial'
                                columns={columns}
                                data={serialList}
                                defaultSortField="serial"
                                defaultSortFieldId={1}
                                highlightOnHover
                                paginationPerPage={5}
                                paginationRowsPerPageOptions={[5, 10, 15, 20, 25]}
                                progressPending={loading}
                            />
                            {
                                (formik.touched.serials && formik.errors.serials) ? <FormHelperText error>{formik.errors.serials}</FormHelperText> : null
                            }
                        </Container>
                    </BoxItem>
                </Stack>
            </Box>
            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                open={openSnackBar}
                onClose={handleCloseSnackBar}
                autoHideDuration={4000}
            >
                <Alert severity={(message["status"]) ? "success" : "error"} onClose={handleCloseSnackBar}>
                    {
                        (!_.isEmpty(message)) ? message.message : null
                    }
                </Alert>
            </Snackbar>
        </Box>
    );
}

export default UpdateBulkStage;