import { useState } from "react";

// MUI Components
import {
    Box, Tabs, Tab
} from '@mui/material';

// Components
import SectionHeader from "../../components/SectionHeader/SectionHeader.component";
// import Search from "../../components/Search/Search.component";
import UsersList from "../../layouts/Users/Users.layout";
import InvitatedUsers from "../../components/Users/Invitation.component";

// Tab Panel
function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 0 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}


export default function UserList(props) {
    const [value, setValue] = useState(0);

    // Handle change for tabs
    const handleChange = (event, newValue) => {
        setValue(newValue);
    }

    return (
        <Box>
            {/* Sectoin Header */}
            <SectionHeader heading="Users" />

            <Box sx={{ flexGrow: 1, my: 2 }}>
                {/* Tab Header */}
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" >
                        <Tab label="Users" {...a11yProps(0)} />
                        <Tab label="Invitations" {...a11yProps(1)} />
                    </Tabs>
                </Box>
                {/* Tab Body */}
                {/* Users */}
                <TabPanel value={value} index={0} centered>
                    <UsersList />
                </TabPanel>

                {/* Inivitaions */}
                <TabPanel value={value} index={1} centered>
                    <InvitatedUsers />
                </TabPanel>
            </Box>
        </Box>
    );
}