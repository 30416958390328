import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import _ from 'lodash';

import {
    styled
} from "@mui/material/styles";

// MUI Component
import {
    Box, Typography, Grid, Button, IconButton
} from "@mui/material";

// MUI icons
import AddIcon from "@mui/icons-material/Add";

// Components
import StockCard from "../components/Cards/StockCard.component";
import Search from "../components/Search/Search.component";
import Loading from '../components/Common/Loading.component';
// Dummy Data
import axios from "axios";
import SyncIcon from "@mui/icons-material/Sync";


const Item = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));
function SearchQuery(data, queryString) {
    const query = queryString.toLowerCase();
    const filteredData = data.filter((d) => {
        const n = d._id.toLowerCase();
        return n.includes(query);
    }, data);
    return filteredData
}
export default function Stock(props) {
    const navigate = useNavigate();
    const [state, setState] = useState([]);
    const [loading, setLoading] = useState(false);
    const [queryInput, setQueryInput] = useState("");

    const getData = async () => {
        setLoading(true);
        const response = await axios.get('/warehouse/stock');
        const result = await response.data;
        setState(result.data);
    }
    const handleQueryInput = (event) => {
        let query = event.target.value.trim().toLowerCase();
        setQueryInput(query);
    }

    useEffect(() => {
        getData();
    }, []);

    useEffect(() => {
        if (!_.isEmpty(state)) setLoading(false);
    }, [state]);


    return (
        <Box>
            <Box sx={{ flexGrow: 1, py: 2, pl: 2 }}>
                <Grid container direction="row"
                    justifyContent="space-between"
                    alignItems="flex-start"
                    spacing={1}
                >
                    <Grid item xs={2} md={"auto"}>
                        <Typography variant="h6" component="div">
                            {"Available Stock"}
                        </Typography>
                    </Grid>
                    <Grid item xs={10} md={"auto"}>
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={12} md={"auto"} lg={"auto"}>
                                <IconButton aria-label="refresh" onClick={getData}>
                                    <SyncIcon />
                                </IconButton>
                            </Grid>
                            <Grid item xs={12} sm={12} md={"auto"} lg={"auto"}>
                                <Search id="search-stock" handleChange={handleQueryInput} placeholder="Search AG-702..." />
                            </Grid>
                            <Grid item xs={12} sm={12} md={"auto"} lg={"auto"}>
                                <Button
                                    variant="contained"
                                    size="small"
                                    sx={{ float: 'right' }}
                                    startIcon={<AddIcon />}
                                    onClick={() => navigate("addstock")}
                                >Add Stock</Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
            <Box>
                <Box sx={{
                    m: 0,
                    p: 1,
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                }}>
                    {
                        loading ? <Loading /> :
                            SearchQuery(state, queryInput).map(c => (
                                <Item key={c._id}>
                                    <StockCard data={c} />
                                </Item>
                            ))
                    }
                </Box>
            </Box>
        </Box>
    )
}