import {
    Box, Button, Dialog, DialogTitle, DialogContent, DialogActions, DialogContentText, TextField
} from "@mui/material";
import { Formik, Form, Field } from "formik";
import * as Yup from 'yup';
import axios from "axios";


const validationSchema = Yup.object().shape({
    name: Yup.string().required('Name is required')
});

export default function FormDialog({ open, handleClose, getData, setMessage }) {

    const postData = async (data) => {
        const response = await axios.post('/distributors', data);
        const result = await response.data;

    }
    const handleSubmit = (values) => {
        // console.log(values);
        // setSubmitting(false);
        postData(values);
        handleClose();
        getData();
        setMessage(true);
    };

    return (
        <Box>
            <Dialog open={open} onClose={handleClose}>
                <Formik
                    initialValues={{ name: '' }}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                >
                    {({ errors, touched, isSubmitting }) => (
                        <Form>
                            <DialogTitle>New Distributor</DialogTitle>
                            <DialogContent>
                                <DialogContentText>
                                    Add new distributor's name. For us distributor is b2b business.
                                </DialogContentText>
                                <Field
                                    as={TextField}
                                    autoFocus
                                    fullWidth
                                    margin="normal"
                                    variant="standard"
                                    id="name"
                                    name="name"
                                    label="Name"
                                    error={touched.name && Boolean(errors.name)}
                                    helperText={touched.name && errors.name}
                                />
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleClose}>Cancel</Button>
                                <Button variant="contained" type="submit">Add</Button>
                            </DialogActions>
                        </Form>
                    )}
                </Formik>
            </Dialog>
        </Box>
    )
}