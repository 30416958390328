import * as React from "react";
import { useNavigate } from "react-router-dom";
// Material UI
import Box from "@mui/material/Box";

// Other Components
import SectionHeader from "../../components/SectionHeader/SectionHeader.component";
import Products from "./Products.section";
import FeaturesSection from "../../layouts/Features.layout";
import InterfacesSection from "../../layouts/Interfaces.layout";

export default function ProductList() {
    return (
        <Box>
            {/* SectionHeader */}
            <SectionHeader heading="Products" />
            <Products />
            <FeaturesSection />
            <InterfacesSection />
        </Box >
    );
}