import { useState } from 'react';
import { Box, Typography, Tabs, Tab } from '@mui/material';
import PropTypes from 'prop-types';
// Custom Components
import SectionHeader from '../components/SectionHeader/SectionHeader.component';
import AddSingleStock from '../pages/Forms/AddStock.component';
import AddBulkStock from '../pages/Forms/AddBulkStock.component';
const TabPanel = (props) => {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}
TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};
const AddStocks = () => {
    const [value, setValue] = useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    }
    return (
        <Box>
            <SectionHeader heading='Add Stock' />
            <Box>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    // variant="fullWidth"
                    aria-label="Add stock tabs"
                    centered
                >
                    <Tab label="Manually" />
                    {/* <Tab label="Location" /> */}
                    <Tab label="From File" />
                </Tabs>
                <TabPanel value={value} index={0}>
                    <AddSingleStock />
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <AddBulkStock />
                </TabPanel>
            </Box>
        </Box>
    )
}

export default AddStocks;
