import Chip from "@mui/material/Chip"

const Stage = ({ s }) => {
    if (s === 1) return <Chip label="store" size="small" style={{ backgroundColor: "#673AB7", color: "#F2F2F2" }} />;
    if (s === 2) return <Chip label="reserved" size="small" style={{ backgroundColor: "#D9B03B", color: "#F2F2F2" }} />;
    if (s === 3) return <Chip label="shipping" size="small" style={{ backgroundColor: "#E03997", color: "#F2F2F2" }} />;
    if (s === 4) return <Chip label="installed" size="small" style={{ backgroundColor: "#28B25F", color: "#F2F2F2" }} />;
    if (s === 5) return <Chip label="returned" size="small" style={{ backgroundColor: "#FBBD08", color: "#F2F2F2" }} />;
    if (s === 6) return <Chip label="repair" size="small" style={{ backgroundColor: "#F2711C", color: "#F2F2F2" }} />;
    if (s === 7) return <Chip label="scrapped" size="small" style={{ backgroundColor: "#DB445F", color: "#F2F2F2" }} />;
}
export default Stage;