import React, { useEffect, useState } from "react";
import _ from "lodash";
import axios from "axios";
// import * as Yup from "yup";
import { Formik, Form, Field } from 'formik';
import { styled } from "@mui/material/styles";
import {
    Box, Stack, Divider, Grid, Card, CardHeader, TextField, CardContent, Typography, Checkbox, FormControlLabel, Button,
} from "@mui/material";
import CheckingBoxes from "../../assets/CheckingBoxes.svg";
import SectionHeader from "../../components/SectionHeader/SectionHeader.component";



const Item = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

const CardItem = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(0),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

const PermissionCard = ({ section, values, setFieldValue, handleSelectAll }) => (
    <Card sx={{ maxWidth: 345 }}>
        <CardHeader
            action={
                <Checkbox
                    checked={Object.values(values.permissions[section]).every((permission) => permission)}
                    onChange={() =>
                        setFieldValue('permissions', handleSelectAll(values.permissions, section))
                    }
                />
            }
            title={
                <Typography variant="subtitle1" sx={{ color: '#808080' }}>
                    {section.charAt(0).toUpperCase() + section.slice(1)}
                </Typography>
            }
        />
        <CardContent>
            <Stack
                // direction={{ xs: 'column', sm: 'row', md: 'row', lg: 'row', xl: 'row' }}
                direction="column"
                justifyContent="flex-start"
                alignItems="flex-start"
                // spacing={{ xs: 1, sm: 2, md: 4, lg:4, xl:8 }}
                spacing={0}
            >
                {
                    Object.keys(values.permissions[section]).map((permission) => (
                        <CardItem>
                            <Field key={permission} type="checkbox" name={`permissions.${section}.${permission}`}>
                                {({ field }) => (
                                    <FormControlLabel
                                        control={<Checkbox {...field} />}
                                        label={permission.charAt(0).toUpperCase() + permission.slice(1)}
                                    />
                                )}
                            </Field>
                        </CardItem>
                    ))
                }
            </Stack>
        </CardContent>
    </Card>
)

const CreateRole = () => {
    const [initialValues, setInitialValues] = useState({});
    const handleSelectAll = (permissions, section) => {
        const allSelected = Object.values(permissions[section]).every((permission) => permission);
        const updatedPermissions = { ...permissions };
        Object.keys(updatedPermissions[section]).forEach((key) => {
            updatedPermissions[section][key] = !allSelected;
        });
        return updatedPermissions;
    };


    // const handleSelect = () => {
    // }
    // const handelChange = () => {

    // }

    const handleSubmit = (values) => {

        // alert(JSON.stringify(values, null, 2));
        // Perform form submission
    };

    const getData = async () => {
        const response = await axios.get('/sections/permissionsListType2');
        const result = await response.data;
        setInitialValues({
            name: '',
            permissions: result
        })
    }
    useEffect(() => {
        getData();
    }, []);
    return (
        <Box>
            {/* Section Header */}
            <SectionHeader heading="Create New Role" />
            {(!_.isEmpty(initialValues)) ?
                (<Box >
                    <Formik initialValues={initialValues} onSubmit={handleSubmit}>
                        {({ values, setFieldValue }) => (
                            <Form>
                                <Stack
                                    direction="row"
                                    divider={<Divider orientation="vertical" flexItem />}
                                    justifyContent="space-evenly"
                                    alignItems="flex-start"
                                    spacing={2}
                                >

                                    <Item >
                                        <Box sx={{ my: 3, width: '100%' }}>
                                            <Typography variant="h6">Select Permissions</Typography>
                                        </Box>

                                        <Box sx={{ width: '100%', height: 'calc(100vh - 200px)', overflowY: 'scroll' }}>
                                            <Grid container spacing={1}>
                                                {Object.keys(values.permissions).map((section) => (
                                                    <Grid key={section} item xs={12} sm={12} md={6} lg={4}>
                                                        <PermissionCard key={section} section={section} values={values} handleSelectAll={handleSelectAll} setFieldValue={setFieldValue} />
                                                    </Grid>

                                                ))}
                                            </Grid>
                                        </Box>

                                    </Item>
                                    <Item sx={{ minWidth: '300px' }}>
                                        <Stack
                                            sx={{ mt: 10 }}
                                            direction="column"
                                            justifyContent="flex-start"
                                            alignItems="stretch"
                                            spacing={3}
                                        >
                                            <Item>
                                                <img src={CheckingBoxes} width="150px" height="auto" alt="Role_icon" />
                                            </Item>
                                            <Item>
                                                <TextField
                                                    label="Role name"
                                                    name="name"
                                                    value={values.name}
                                                    onChange={(e) => setFieldValue('name', e.target.value)}
                                                    fullWidth
                                                    margin="normal"
                                                />
                                            </Item>
                                            <Item>
                                                <Button
                                                    type="submit"
                                                    variant="contained"
                                                    disabled={true}
                                                    fullWidth>
                                                    Create Role
                                                </Button>
                                            </Item>
                                        </Stack>
                                    </Item>
                                </Stack>
                            </Form>
                        )}
                    </Formik>
                </Box>
                ) : null
            }
        </Box>
    );
};

export default CreateRole;
