import * as React from "react";
// Routing
import { Routes, Route } from "react-router-dom";

// Other Components
import RoleList from "./List.page";
import CreateRole from "../Forms/CreateRole.component";

export default function Roles(props) {
    return (
        <Routes>
            <Route path="/" element={<RoleList />} />
            <Route path="/createRole" element={<CreateRole />} />
            <Route path="/:id" element={<h5>Role Details</h5>} />
        </Routes>
    )
}