import { ClientJS } from "clientjs";
import md5 from 'crypto-js/md5';
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import UserPool from "./UserPool";
// Extend plugins
dayjs.extend(utc);
dayjs.extend(timezone);

// This funciton generates browsers fingerprints
export const getFingerprint = () => {
    const c = new ClientJS();
    const fp = String(c.getFingerprint());
    return md5(fp).toString();
}

/**
 * Local TIme management
 * Timezone
 */

export const setDefaultTimezone = () => {
    localStorage.setItem("timezone", dayjs.tz.guess());
}

export const getDefaultTimezone = () => {
    return dayjs.tz.guess();
}

export const getTimezone = () => {
    return localStorage.getItem("timezone");
}

export const setTimeZone = (t) => {
    // if (!t) {
    //     setDefaultTimezone();
    //     return;
    // }
    localStorage.setItem("timezone", t);
}

/**
 * 
 * @returns Token Management
 */
export const getToken = () => {
    const t = localStorage.getItem('ACCESS_TOKEN');
    return t;
}

export const setToken = (t) => {
    localStorage.setItem('ACCESS_TOKEN', t);
}

// User management to local storage

export const setUserToLocalStorage = (user) => {
    setToken(user['token']);
    // Set local timezone
    // Set user  to localstorage
}

export const getUserFromLocalStorage = () => {
    const user = localStorage.getItem('user');
    return user ? JSON.parse(atob(user)) : {};
}

export const stringToColor = (string) => {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
}

export const SearchQuery = (queryString, data, callback) => {
    const q = queryString.trim().toLowerCase();
    const d = data.filter(callback(q));
    return d;
}

export const validateSerial = (s) => {
    const pattern = /^([A-Za-z0-9]{4}-){3}[A-Za-z0-9]{4}$/
    return pattern.test(s);
}