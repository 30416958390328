import react from "react";


//  MUi Components
import {
    Box, Stack, Paper, Divider, Typography, TextField, Button, Grid, Container, Switch
} from "@mui/material";
import { styled } from "@mui/material/styles";
// Custom Components
import SectionHeader from "../../components/SectionHeader/SectionHeader.component";

// Styles and Constants
const label = { inputProps: { 'aria-label': 'Switch demo' } };
const Item = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    // textAlign: 'center',
    color: theme.palette.text.secondary,
}));

// Profile Components
export default function Profile(props) {
    return (
        <Box>
            {/* Sectoin Header */}
            <SectionHeader heading="Profile" />
            <Box sx={{ flexGrow: 1, my: 2 }}>
                <Stack spacing={2}
                    divider={<Divider orientation="horizontal" flexItem />}
                >
                    <Item>
                        <Container maxWidth="xl">
                            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                                <Grid item xs={6}>
                                    <Box sx={{ py: 1, px: 2 }}>
                                        <Typography variant="h6">Email</Typography>
                                        <Typography variant="body2">Here you can change the email address associated with your account. Before you can change your email address, you will have to confirm your account password. You will also be sent a confirmation email to your inputted email address. Once confirmed, the account email will be changed.</Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={6}>
                                    <Box sx={{ p: 2 }}>
                                        <TextField fullWidth id="outlined-basic" label="Email*" variant="outlined" size="small" value={'meet@atreyo.in'} InputLabelProps={{ shrink: true }} />

                                    </Box>
                                    <Box sx={{ textAlign: 'center', p: 2 }}>
                                        <Button variant="contained">Update Email</Button>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Container>
                    </Item>
                    <Item>
                        <Container maxWidth="xl">
                            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                                <Grid item xs={6}>
                                    <Box sx={{ py: 1, px: 2 }}>
                                        <Typography variant="h6">Basic Details</Typography>
                                        <Typography variant="body2">This is your main account information. The username you set here can be used to later log in to your account.</Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={6}>
                                    <Box sx={{ p: 2 }}>
                                        <TextField fullWidth id="outlined-basic" label="First Name" variant="outlined" size="small" value={'Meet'} InputLabelProps={{ shrink: true }} />
                                    </Box>
                                    <Box sx={{ p: 2 }}>
                                        <TextField fullWidth id="outlined-basic" label="Last Name" variant="outlined" size="small" value={'Bhadeshiya'} InputLabelProps={{ shrink: true }} />
                                    </Box>
                                    <Box sx={{ textAlign: 'center', p: 2 }}>
                                        <Button variant="contained">Save</Button>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Container>
                    </Item>
                    <Item>
                        <Container maxWidth="xl">
                            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                                <Grid item xs={6}>
                                    <Box sx={{ py: 1, px: 2 }}>
                                        <Typography variant="h6">Password</Typography>
                                        <Typography variant="body2">After a successful password update, you will be redirected to the login page where you can log in with your new password. Keep in mind, you cannot change your password to one that has been recently used by this account.</Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={6}>
                                    <Box sx={{ p: 2 }}>
                                        <TextField fullWidth id="outlined-basic" label="New password" variant="outlined" size="small" />
                                    </Box>
                                    <Box sx={{ p: 2 }}>
                                        <TextField fullWidth id="outlined-basic" label="Confirm new password" variant="outlined" size="small" />
                                    </Box>
                                    <Box sx={{ textAlign: 'center', p: 2 }}>
                                        <Button variant="contained">Save Password</Button>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Container>
                    </Item>
                    <Item>
                        <Container maxWidth="xl">
                            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                                <Grid item xs={6}>
                                    <Box sx={{ py: 1, px: 2 }}>
                                        <Typography variant="h6">MFA setting</Typography>
                                        <Typography variant="body2">Multi-Factor Authenitcation.</Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={6}>
                                    <Box sx={{ p: 2 }}>
                                        <Switch {...label} />
                                    </Box>
                                </Grid>
                            </Grid>
                        </Container>
                    </Item>
                </Stack>
            </Box>
        </Box >
    );
}