
let replacedDevices = [];

for (let i = 0; i < 20; i++) {
    replacedDevices.push({
        "replacementId": (i + 1),
        "serialNew": `xxxxx${i + 1}`,
        "serialOld": `xxxxx${i + 1}`,
        "model": "xxx",
        "company": "x-company",
        "date": "xxxx-xx-xx",
        "updatedBy": "x-user"
    });
}
export default replacedDevices;