import * as React from "react"
import {
    useTheme, styled
} from "@mui/material/styles";
// MUI Components
import {
    Box, Typography, Card, CardContent, CardMedia, IconButton
} from "@mui/material";
// MUI Icons
const cardStyle = {
    display: 'flex',
    backgroundImage: 'linear-gradient(-65deg, #ABD9E6 0%, #48A89D 27%, #134E5E 93%)',
}

const cardContentStyle = {
    display: 'flex',
    flexDirection: 'column',
    pb: 0,
    // backgroundColor: '#48A89D',
    backgroundColor: 'rgba(0,0,0,0)',
    // backgroundImage: 'linear-gradient(135deg, #ABD9E6 0%, #48A89D 27%, #134E5E 93%)',
    height: '100%'
}
const cardMediaStyle = {
    width: 151,
    backgroundColor: 'rgba(0,0,0,0)',

}

export default function StockCard(props) {
    const theme = useTheme();
    const stock = props.data;

    return (
        <Card sx={cardStyle}>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <CardContent sx={cardContentStyle}>
                    <Box sx={{ display: 'flex', alignItems: 'center', pl: 3, pb: 0 }}>
                        <Typography variant="h2" color="#fff" component="div">
                            {
                                stock.quantity ? stock.quantity : "STOCK NUMBER"
                            }
                        </Typography>
                    </Box>
                    {/* <hr width="inherit" /> */}
                    <Typography component="div" variant="h5" color="#f2ba01">
                        {
                            stock._id ? stock._id : "PRODUCT NAME"
                        }
                    </Typography>
                    {/* <Typography variant="subtitle1" color="text.secondary" component="div">
                        {
                            stock.updatedBy ? stock.updatedBy : "USER NAME"
                        }
                    </Typography>
                    <Typography variant="caption" color="text.secondary" component="div">
                        {"7 days ago"}
                    </Typography> */}
                </CardContent>
            </Box>
            <CardMedia
                component="img"
                sx={cardMediaStyle}
                // src="https://www.atreyo.in/sites/default/files/styles/max_1300x1300/public/a-gallery-products/AG-802-01.png"
                src={stock.imageUrl}

                alt="Live from space album cover"
            />
        </Card >
    )
}