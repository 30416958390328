import { useState, useEffect } from "react";
import _ from 'lodash';
import axios from "axios";
// Routing
import {
    useNavigate,
} from "react-router-dom";
// MUI Components
import {
    Box, Typography, IconButton, Grid, Button
} from '@mui/material';

// Material Icons
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import SyncIcon from '@mui/icons-material/Sync';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from "@mui/icons-material/Delete";
import ArticleRoundedIcon from '@mui/icons-material/ArticleRounded';

// Components
import SectionHeader from "../../components/SectionHeader/SectionHeader.component";
import Search from "../../components/Search/Search.component";
import DataTable from "../../components/DataTable/DataTable.component";

const NavigationComponent = ({ id }) => {
    const navigate = useNavigate();
    return (
        <IconButton onClick={() => navigate(id)}>
            <ArticleRoundedIcon />
        </IconButton>
    );
}

const columns = [
    {
        name: "Role",
        selector: row => row.name,
        sortable: true,
    },
    {
        name: "Actions",
        selector: row => row._docId,
        cell: row => <NavigationComponent id={row._docId} />
    }
]

const actions = ({ handleOpen, handleChange, getData }) => (
    <Grid
        container
        alignItems="flex-start"
        justifyContent="flex-end"
        spacing={1}
    >
        <Grid item xs={12} sm={12} md={"auto"} lg={"auto"}>
            <IconButton aria-label="refresh" onClick={getData}>
                <SyncIcon />
            </IconButton>
        </Grid>
        <Grid item xs={12} sm={12} md={"auto"} lg={"auto"}>
            <Search id={"search-roles"} placeholder={"Search..."} handleChange={handleChange} />
        </Grid>
        <Grid item xs={12} sm={12} md={"auto"} lg={"auto"}>
            <Button
                variant="contained"
                size="small"
                sx={{ float: 'right' }}
                startIcon={<AddIcon />}
                onClick={handleOpen}
            >Add</Button>
        </Grid>
        <Grid item xs={12} sm={12} md={"auto"} lg={"auto"}>
            <Button disabled variant="contained" size="small" sx={{ float: 'right' }} endIcon={<FilterAltIcon />}>Filter</Button>
        </Grid>
    </Grid>
);

const contextActions = deleteHandler => (
    <IconButton onClick={deleteHandler}>
        <DeleteIcon />
    </IconButton>
);
const title = <Typography variant='h6'>{'Roles'}</Typography>;

function SearchQuery(queryString, data) {
    const query = queryString.toLowerCase();
    const filteredData = data.filter((d) => {
        const n = d.name.toLowerCase();
        return n.includes(query);
    }, data);
    return filteredData
}
// Roles List Component
export default function RoleList(props) {

    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [queryInput, setQueryInput] = useState('');
    const [data, setData] = useState([]);
    const [toggleCleared, setToggleCleared] = useState(false);
    const [selectedRows, setSelectedRows] = useState([]);

    const handleNavigate = () => navigate('createRole')

    const handleChange = (s) => {
        setSelectedRows(s.selectedRows);
    }
    const deleteAll = () => {
        const rows = selectedRows.map(r => r.name);
        if (window.confirm(`Are you sure you want to delete?`)) {
            setToggleCleared(!toggleCleared);
            setData(_.differenceBy(data, rows, 'name'));
        }
    }
    const handleQueryInput = (event) => {
        let query = event.target.value.trim().toLowerCase();
        setQueryInput(query);
    }
    const getData = async () => {
        try {
            setLoading(true);
            const response = await axios.get("/roles");
            const result = await response.data;
            if (result.status) {
                setData(result.data);
            }
        } catch (error) {
            console.log("ROLE ERROR", error);
        }
    }

    useEffect(() => {
        getData();
        return () => {
            setData([]);
        }
    }, []);

    useEffect(() => {
        if (!_.isEmpty(data)) setLoading(false);
    }, [data]);

    return (
        <Box>
            {/* Sectoin Header */}
            <SectionHeader heading="Roles" />
            <Box sx={{ flexGrow: 1, my: 2 }}>
                {/* <Box >
                    <Grid container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="flex-start"
                        spacing={1}>
                        <Grid item xs={12} md={"auto"}>
                            <Button
                                variant="contained"
                                size="small"
                                startIcon={<AddIcon />}
                                onClick={() => navigate("createRole")}
                            >Create Role</Button>
                        </Grid>
                        <Grid item xs={12} md={"auto"}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={12} md={"auto"} lg={"auto"}>
                                    <IconButton aria-label="refresh" onClick={getData}>
                                        <SyncIcon />
                                    </IconButton>
                                </Grid>
                                <Grid item xs={12} sm={12} md={"auto"} lg={"auto"}>
                                    <Search id='search-roles' handleChange={handleQueryInput} placeholder='Search role...' />
                                </Grid>
                                <Grid item xs={12} sm={12} md={"auto"} lg={"auto"}>
                                    <Button variant="contained" size="small" sx={{ float: 'right' }} endIcon={<FilterAltIcon />}>Filter</Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box> */}
                <Box sx={{ flexGrow: 1, my: 2 }}>
                    <DataTable
                        title={title}
                        columns={columns}
                        data={SearchQuery(queryInput, data)}
                        defaultSortField="name"
                        defaultSortFieldId={1}
                        actions={actions({ handleOpen: handleNavigate, handleChange: handleQueryInput, getData })}
                        contextActions={contextActions(deleteAll)}
                        onSelectedRowsChange={handleChange}
                        selectableRowsNoSelectAll
                        clearSelectedRows={toggleCleared}
                        // onRowClicked={handleRowClicked}
                        progressPending={loading}
                    />
                </Box>
            </Box>
        </Box>
    )
}