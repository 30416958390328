import { useState, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import _ from "lodash";
import axios from 'axios';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
// MUI Components
import {
    Box, Typography, Grid, Button, Divider, IconButton, FormControl, FormLabel, MenuItem, TextField, Snackbar, Alert
} from "@mui/material";
// MUI Icons
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from '@mui/icons-material/Add';
import SyncIcon from "@mui/icons-material/Sync";
import EditIcon from '@mui/icons-material/Edit';
// Custom Components
import Search from '../components/Search/Search.component';
import Stage from '../components/Warehosue/Stage.component';
import DataTable from '../components/DataTable/DataTable.component';
import UpdateStageDialog from '../pages/Forms/UpdateStage.component';

const actions = ({ handleNavigate, handleChange, getData }) => (
    <Grid container
        alignItems="flex-start"
        justifyContent="flex-end"
        spacing={1}
    >
        <Grid item xs={12} sm={12} md={"auto"} lg={"auto"}>
            <IconButton aria-label="refresh" onClick={getData}>
                <SyncIcon />
            </IconButton>
        </Grid>
        <Grid item xs={12} sm={12} md={"auto"} lg={"auto"}>
            <Search id={"search-device-tracking"} placeholder={"Search serial..."} handleChange={handleChange} />
        </Grid>
        <Grid item xs={12} sm={12} md={"auto"} lg={"auto"}>
            <Button
                variant="contained"
                size="small"
                sx={{ float: 'right' }}
                startIcon={<AddIcon />}
                onClick={handleNavigate}
            >Import</Button>
        </Grid>
    </Grid>
);

const contextActions = (openDialog, deleteHandler) => (
    <Grid container
        alignItems="flex-start"
        justifyContent="flex-end"
        spacing={1}
    >
        <Grid item md={"auto"} lg={"auto"}>
            <Button
                aria-label="Update-stage"
                variant='contained'
                color='primary'
                startIcon={<EditIcon />}
                onClick={openDialog}
                size="small"
            >
                Update Stage
            </Button>
        </Grid>
        <Grid item md={"auto"} lg={"auto"}>
            <Button
                aria-label="delete-tracking"
                variant='contained'
                color="error"
                endIcon={<DeleteIcon />}
                onClick={deleteHandler}
                size="small"
            >
                Delete
            </Button>
        </Grid>
    </Grid>
);

const columns = [
    {
        name: "Serial",
        selector: row => row['serial'],
        sortable: true,
    },
    {
        name: "Model",
        selector: row => row['modelName'],
        sortable: true
    },
    {
        name: "Stage",
        selector: row => row['stage'],
        sortable: true,
        cell: row => <Stage s={row['stage']} />
    },
    {
        name: "Company",
        selector: row => row['companyName'],
        sortable: true,
    },
    {
        name: "Updated By",
        selector: row => row['meta']['modifiedBy'],
        sortable: true,
        center: true,
    },
    {
        name: "Updated On",
        selector: row => row['meta']['modifiedBy'],
        right: true,
        cell: row => dayjs(row.meta.modifiedAt).format('lll')
    }
];

const SearchQuery = (queryString, data) => {
    const q = queryString.toLowerCase();
    const filteredData = data.filter(d => {
        const s = d.serial.toLowerCase();
        const m = d.modelName.toLowerCase();
        const c = d.companyName.toLowerCase();
        const u = d.meta.modifiedBy.toLowerCase();
        return s.includes(q) || m.includes(q) || c.includes(q) || u.includes(q);
    });
    return filteredData;
}
const title = <Typography variant='h6'>{'Device Tracking'}</Typography>;
const Tracking = () => {
    dayjs.extend(localizedFormat);
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const [data, setData] = useState([]);
    const [queryInput, setQueryInput] = useState('');
    const [loading, setLoading] = useState(false);
    const [toggleCleared, setToggleCleared] = useState(false);
    const [selectedRows, setSelectedRows] = useState([]);
    const [message, setMessage] = useState({});
    const [snackbarOpen, setSnackbarOpen] = useState(false);

    const getData = async () => {
        setLoading(true);
        const response = await axios.get('/warehouse/tracking');
        const result = await response.data;
        setData(result.data);
    }
    const handleNavigate = () => navigate('bulkupdate');
    const handleDialogOpen = () => setOpen(true);
    const handleDialogClose = () => {
        setOpen(false);
        setToggleCleared(!toggleCleared);
    }
    const handleQueryInput = (event) => {
        const query = event.target.value.trim().toLowerCase();
        setQueryInput(query);
    }
    const handleSnakbarClose = () => setSnackbarOpen(false);
    const handleSnakbarOpen = () => setSnackbarOpen(true);
    const handleChange = (s) => {
        setSelectedRows(s.selectedRows);
    }

    const deleteAll = () => {
        const rows = selectedRows.map(r => r.serial);
        if (window.confirm(`Are you sure you want to delete?`)) {
            setToggleCleared(!toggleCleared);
            setData(_.differenceBy(data, selectedRows, 'serial'));
        }
    }

    useEffect(() => { getData(); }, []);
    useEffect(() => {
        if (!_.isEmpty(data)) {
            setLoading(false);
        }
    }, [data]);

    // useEffect(() => { console.log('dialog state', open) }, [open]);

    return (
        <Box sx={{ mt: 10 }}>
            <UpdateStageDialog
                open={open}
                handleClose={handleDialogClose}
                getData={getData}
                selectedRows={selectedRows}
                handleSnakbarOpen={handleSnakbarOpen}
                setMessage={setMessage}
            />
            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                open={snackbarOpen}
                onClose={handleSnakbarClose}
                autoHideDuration={4000}
            >
                <Alert severity={(message.status) ? "success" : "error"} onClose={handleSnakbarClose} sx={{ width: '100%' }}>
                    {
                        message.message ? message.message : null
                    }
                </Alert>
            </Snackbar>
            <Box sx={{ my: 2 }}>
                <DataTable
                    title={title}
                    columns={columns}
                    data={SearchQuery(queryInput, data)}
                    defaultSortField="serial"
                    defaultSortFieldId={1}
                    actions={actions({ handleNavigate, handleChange: handleQueryInput, getData })}
                    contextActions={contextActions(handleDialogOpen, deleteAll)}
                    onSelectedRowsChange={handleChange}
                    selectableRowsNoSelectAll
                    clearSelectedRows={toggleCleared}
                    // onRowClicked={handleRowClicked}
                    progressPending={loading}
                // contextComponent={<CustomContext selectedCount={selectedRows.length} deleteHandler={deleteAll} />}
                />
            </Box>
        </Box>
    );
}
export default Tracking;