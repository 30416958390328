import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import _ from 'lodash';
import axios from 'axios';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';

import {
    Box, Grid, Button, IconButton, Typography
} from '@mui/material';
// MUI Icons
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import SyncIcon from '@mui/icons-material/Sync';
// Custom Component
import Search from '../components/Search/Search.component';
import DataTable from '../components/DataTable/DataTable.component';
// Dummy Data
import replcementData from '../data/replacement';
const columns = [
    {
        name: 'Serial(New)',
        selector: row => row.serialNew,
        sortable: true,
    },
    {
        name: 'Serial(Old)',
        selector: row => row.serialOld,
        sortable: true,
    },
    {
        name: 'Model',
        selector: row => row.model,
        sortable: true,
    },
    {
        name: 'Company',
        selector: row => row.company,
        sortable: true,
    },
    {
        name: 'Date',
        selector: row => row.date,
        sortable: true,
        center: true
    },
    {
        name: 'Updated By',
        selector: row => row.updatedBy,
        sortable: true,
        right: true
    },
];
const actions = ({ handleChange, getData }) => (
    <Grid
        container
        alignItems="flex-start"
        justifyContent="flex-end"
        spacing={1}
    >
        <Grid item xs={12} sm={12} md={"auto"} lg={"auto"}>
            <IconButton aria-label="refresh" onClick={getData}>
                <SyncIcon />
            </IconButton>
        </Grid>
        <Grid item xs={12} sm={12} md={"auto"} lg={"auto"}>
            <Search id={"search-device-replacement"} placeholder={"Search serial..."} handleChange={handleChange} />
        </Grid>
        <Grid item xs={12} sm={12} md={"auto"} lg={"auto"}>
            <Button
                variant="contained"
                size="small"
                sx={{ float: 'right' }}
                startIcon={<AddIcon />}
                // onClick={() => navigate("addStock")}
                disabled
            >Add Replacement</Button>
        </Grid>
    </Grid>
)
const title = <Typography variant='h6'>{'Device Replacement'}</Typography>;
const SearchQuery = (queryString, data) => {
    const q = queryString.toLowerCase();
    const filteredData = data.filter(d => {
        const sN = d.serialNew.toLowerCase();
        const sO = d.serialOld.toLowerCase();
        const m = d.model.toLowerCase();
        const c = d.company.toLowerCase();
        const u = d.updatedBy.toLowerCase();
        return sN.includes(q) || sO.includes(q) || m.includes(q) || c.includes(q) || u.includes(q);
    });
    return filteredData;
}
const DeviceReplacement = () => {
    const [data, setData] = useState([]);
    const [queryInput, setQueryInput] = useState('');
    const [loading, setLoading] = useState(false);
    const [toggleCleared, setToggleCleared] = useState(false);
    const [selectedRows, setSelectedRows] = useState([]);

    const getData = async () => {
        // const response = await axios.get();
        // const result = await response.data;
        setData(replcementData);
    }

    const handleQueryInput = (event) => {
        const query = event.target.value.trim().toLowerCase();
        setQueryInput(query);
    }
    const handleChange = (s) => {
        setSelectedRows(s.selectedRows);
    }
    useEffect(() => { getData(); }, []);
    useEffect(() => {
        if (!_.isEmpty(data)) {
            setLoading(false);
        }
    }, [data]);

    return (
        <Box sx={{ mt: 10 }}>
            <Box sx={{ my: 2 }}>
                <DataTable
                    title={title}
                    columns={columns}
                    data={SearchQuery(queryInput, data)}
                    defaultSortField='serialNew'
                    defaultSortFieldId={1}
                    actions={actions({ handleChange: handleQueryInput, getData })}
                    onSelectedRowsChange={handleChange}
                    selectableRowDisabled={row => true}
                    selectableRowsNoSelectAll
                    progressPending={loading}
                />
            </Box>
        </Box>
    );
}

export default DeviceReplacement;

