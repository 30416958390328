import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import _ from 'lodash';
// Mui Components
import {
    Box, Button, IconButton, Stack, FormControl, FormLabel, Grid, MenuItem,
    TextField, Typography, Divider, Snackbar, Alert
} from '@mui/material';
import { styled } from "@mui/material/styles";

// Material Icons
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import RemoveCircleRoundedIcon from '@mui/icons-material/RemoveCircleRounded';
import CreateIcon from '../../assets/Create.svg';
// Custom Components
import SectionHeader from '../../components/SectionHeader/SectionHeader.component';


const Item = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

const validationSchema = Yup.object().shape({
    modelName: Yup.string().required('Model name is required'),
    modelImageUrl: Yup.string().url('Invalid URL').required('Model image URL is required'),
    features: Yup.array().of(
        Yup.object().shape({
            feature: Yup.string().required('Feature is required'),
            interface: Yup.string().required('Interface is required'),
            slots: Yup.number().integer().min(1).max(20),
        })
    ),
});

const initialValues = {
    modelName: '',
    modelImageUrl: '',
    features: [{ feature: '', interface: '', slots: '1' }],
};

const ProductForm = () => {
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState({});
    const [featuresList, setFeatureList] = useState([]);
    const [interfaceList, setInterfaceList] = useState([]);
    const navigate = useNavigate();
    // get feature options
    const getFeatureList = async () => {
        const response = await axios.get('/features/getFeaturesListType2');
        const result = await response.data;
        setFeatureList(result.data);
    }
    // get interface option
    const getInterfaceList = async () => {
        const response = await axios.get('/interfaces/getInterfaceListType2');
        const result = await response.data;
        setInterfaceList(result.data);
    }

    const postData = async (data) => {
        const response = await axios.post('/models', data);
        const result = await response.data;
        setMessage(result);
        setOpen(true);
    }

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: (values) => {
            // Save the form values
            postData(values);
        },
    });

    const handleFeatureChange = (index, value) => {
        const features = [...formik.values.features];
        // features[index] = { ...features[index], feature: value, interface: '', slots: '1' };
        features[index] = { ...features[index], feature: value };
        formik.setFieldValue('features', features);
    };

    const handleInterfaceChange = (index, value) => {
        const features = [...formik.values.features];
        features[index] = { ...features[index], interface: value };
        formik.setFieldValue('features', features);
    };

    const handleSlotsChange = (index, value) => {
        const features = [...formik.values.features];
        features[index] = { ...features[index], slots: value };
        formik.setFieldValue('features', features);
    };

    const handleAddFeature = () => {
        const features = [...formik.values.features];
        features.push({ feature: '', interface: '', slots: '1' });
        formik.setFieldValue('features', features);
        getFeatureList();
        getInterfaceList();
    };

    const handleRemoveFeature = (index) => {
        const features = [...formik.values.features];
        features.splice(index, 1);
        formik.setFieldValue('features', features);
    };

    const handleClose = () => {
        setOpen(false);
        navigate('/products', { replace: true });
    }

    useEffect(() => {
        getFeatureList();
        getInterfaceList();
    }, []);

    // useEffect(() => {
    //     if (!open) {
    //         navigate('/products', { replace: true });
    //     }
    //     return () => {
    //         setMessage({});
    //         setFeatureList([]);
    //         setInterfaceList([]);
    //     }
    // }, [open]);

    return (
        <Box>
            <SectionHeader heading='Create Product' />
            {/* <Container maxWidth="lg"> */}
            <form onSubmit={formik.handleSubmit}>
                <Stack
                    direction="row"
                    divider={<Divider orientation="vertical" flexItem />}
                    justifyContent="space-evenly"
                    alignItems="flex-start"
                    spacing={2}
                >

                    <Item>
                        <Stack
                            direction="column"
                            justifyContent="flex-start"
                            alignItems="center"
                            spacing={1}
                        // divider={<Divider orientation="horizontal" flexItem />}
                        >
                            <Item>
                                <Typography variant="h6" >
                                    Features
                                </Typography>
                            </Item>
                            <Item>
                                <Stack
                                    direction="column"
                                    justifyContent="center"
                                    alignItems="center"
                                    spacing={1}
                                >
                                    {formik.values.features.map((feature, index) => (
                                        <Item key={index}>
                                            <Grid
                                                container
                                                justifyContent="center"
                                                alignItems="center"
                                                spacing={2}

                                            >
                                                <Grid item xs={12} md={4}>
                                                    <FormControl
                                                        fullWidth
                                                        error={formik.touched.features && Boolean(formik.errors.features)}
                                                    >
                                                        <FormLabel component="legend">Feature {index + 1}</FormLabel>
                                                        <TextField
                                                            size="small"
                                                            select
                                                            value={feature.feature}
                                                            onChange={(e) => handleFeatureChange(index, e.target.value)}
                                                        >
                                                            {featuresList.map((option, i) => (
                                                                <MenuItem key={i} value={option._docId}>
                                                                    {option.name}
                                                                </MenuItem>
                                                            ))}
                                                        </TextField>
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} md={4}>
                                                    <FormControl fullWidth>
                                                        <FormLabel component="legend">Interface</FormLabel>
                                                        <TextField
                                                            size="small"
                                                            select
                                                            value={feature.interface}
                                                            onChange={(e) => handleInterfaceChange(index, e.target.value)}
                                                        >
                                                            {interfaceList.map((option, i) => (
                                                                <MenuItem key={i} value={option._docId}>
                                                                    {option.name}
                                                                </MenuItem>
                                                            ))}
                                                        </TextField>
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} md={3}>
                                                    <FormControl fullWidth>
                                                        <FormLabel component="legend">Slots</FormLabel>
                                                        <TextField
                                                            size="small"
                                                            type="number"
                                                            value={feature.slots}
                                                            onChange={(e) => handleSlotsChange(index, e.target.value)}
                                                        />
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} md={1}>
                                                    <IconButton
                                                        sx={{ mt: 3 }}
                                                        onClick={() => handleRemoveFeature(index)}
                                                        variant="outlined"
                                                        aria-label="Remove"
                                                        color="error"
                                                    >
                                                        <RemoveCircleRoundedIcon />
                                                    </IconButton>
                                                </Grid>
                                            </Grid>
                                        </Item>
                                    ))}
                                </Stack>
                            </Item>
                            <Item>
                                <IconButton variant="contained"
                                    onClick={handleAddFeature}
                                    aria-label="Add Feature"
                                    color="warning"
                                >
                                    <AddCircleRoundedIcon />
                                </IconButton>
                            </Item>
                        </Stack>
                    </Item>
                    <Item >
                        <Stack
                            sx={{ mt: 10 }}
                            direction="column"
                            justifyContent="flex-start"
                            alignItems="stretch"
                            spacing={3}
                        // divider={<Divider orientation="horizontal" flexItem />}
                        >
                            <Item>
                                <TextField
                                    fullWidth
                                    size="small"
                                    label="Model Name"
                                    id="modelName"
                                    name="modelName"
                                    value={formik.values.modelName}
                                    onChange={formik.handleChange}
                                    error={formik.touched.modelName && Boolean(formik.errors.modelName)}
                                    helperText={formik.touched.modelName && formik.errors.modelName}
                                />
                            </Item>
                            <Item>
                                <TextField
                                    fullWidth
                                    size="small"
                                    label="Model Image URL"
                                    id="modelImageUrl"
                                    name="modelImageUrl"
                                    value={formik.values.modelImageUrl}
                                    onChange={formik.handleChange}
                                    error={formik.touched.modelImageUrl && Boolean(formik.errors.modelImageUrl)}
                                    helperText={formik.touched.modelImageUrl && formik.errors.modelImageUrl}
                                />
                            </Item>
                            <Item>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    disabled={!formik.isValid}
                                    fullWidth>
                                    Create Product
                                </Button>
                            </Item>
                            <Item sx={{ mt: 2 }}>
                                <img src={CreateIcon} width='221px' alt='create icon' />
                            </Item>
                        </Stack>
                    </Item>
                    {/* <Item sx={{ mt: 10 }}>
                        <Button
                            type="submit"
                            variant="contained"
                            disabled={!formik.isValid}
                            fullWidth>
                            Create Product
                        </Button>
                    </Item> */}
                </Stack>
            </form>
            {/* </Container> */}
            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                open={open}
                onClose={handleClose}
                autoHideDuration={4000}
            >
                <Alert severity={message.status ? "success" : "error"} onClose={handleClose}>
                    {
                        (!_.isEmpty(message)) ? message.message : null
                    }
                </Alert>
            </Snackbar>
        </Box>
    );
};

export default ProductForm;




