import * as React from "react";

// Material Icons
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import CloudCircleRoundedIcon from '@mui/icons-material/CloudCircleRounded';
import LocationOnIcon from '@mui/icons-material/LocationOn';

import WarehouseIcon from '@mui/icons-material/Warehouse';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import HowToRegRoundedIcon from '@mui/icons-material/HowToRegRounded';
import GroupRoundedIcon from '@mui/icons-material/GroupRounded';
import MonetizationOnRoundedIcon from '@mui/icons-material/MonetizationOnRounded';
import { GrProductHunt } from 'react-icons/gr';
import { FaUserLock } from 'react-icons/fa';
import { RiRouterFill } from 'react-icons/ri';


const DashoardItems = [
    {
        "id": "overview",
        "title": "Overview",
        icon: <HomeRoundedIcon />,
        "path": "/",
    },
    {
        "id": "sales",
        "title": "Sales",
        icon: <MonetizationOnRoundedIcon />,
        "path": "/sales",
    },
    {
        "id": "cloud",
        "title": "Cloud",
        icon: <CloudCircleRoundedIcon />,
        "path": "/cloud",
    }, {
        "id": "devices",
        "title": "Devices",
        icon: <RiRouterFill size='24px' />,
        "path": "/devices",
    },
    {
        "id": "map",
        "title": "Map",
        icon: <LocationOnIcon />,
        "path": "/map",
    },
];

const MangementItems = [
    {
        "id": "products",
        "title": "Products",
        icon: <GrProductHunt size='24px' />,
        "path": "/products",
    },
    {
        "id": "warehouse",
        "title": "Warehouse",
        icon: <WarehouseIcon />,
        "path": "/warehouse",
    },
    {
        "id": "distributors",
        "title": "Distributors",
        icon: <LocalShippingIcon />,
        "path": "distributors",
    },
    {
        "id": "endcustomer",
        "title": "End-Customers",
        icon: <HowToRegRoundedIcon />,
        "path": "/endcustomers",
    },
    {
        "id": "users",
        "title": "Users",
        icon: <GroupRoundedIcon />,
        "path": "/users",
    },
    {
        "id": "roles",
        "title": "Roles",
        icon: <FaUserLock size='24px' />,
        "path": "/roles",
    },
]

export { DashoardItems, MangementItems }; 