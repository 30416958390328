import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import _ from "lodash";
import axios from "axios";
import dayjs from "dayjs";
import localizedFormat from 'dayjs/plugin/localizedFormat';

// MUI Components
import {
    Box, Typography, IconButton, Grid, Button, Snackbar, Alert
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
// Material Icons

import FilterAltIcon from '@mui/icons-material/FilterAlt';
import DeleteIcon from "@mui/icons-material/Delete";
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import SyncIcon from '@mui/icons-material/Sync';
// Custom Components
import SectionHeader from "../../components/SectionHeader/SectionHeader.component";
import Search from "../../components/Search/Search.component";
import FormDialog from "../../components/Distributors/Dialog.component";
import Loading from "../../components/Common/Loading.component";
import DataTable from "../../components/DataTable/DataTable.component";

const columns = [
    {
        name: "Name",
        selector: row => row['name'],
        sortable: true,

    },
    {
        name: "Created At",
        selector: row => row['meta']['createdAt'],
        sortable: true,
        cell: row => dayjs(row.meta.createdAt).format('lll'),
        right: true

    }
];

function SearchQuery(queryString, data) {
    const query = queryString.toLowerCase();
    const filteredData = data.filter((d) => {
        const n = d.name.toLowerCase();
        return n.includes(query);
    }, data);
    return filteredData
}
const actions = ({ handleClickOpen, handleChange, getData }) => (
    <Grid container
        alignItems="flex-start"
        justifyContent="flex-end"
        spacing={1}
    >
        <Grid item xs={12} sm={12} md={"auto"} lg={"auto"}>
            <IconButton aria-label="refresh" onClick={getData}>
                <SyncIcon />
            </IconButton>
        </Grid>
        <Grid item xs={12} sm={12} md={"auto"} lg={"auto"}>
            <Search id={"search-distributor"} placeholder={"Search..."} handleChange={handleChange} />
        </Grid>
        <Grid item xs={12} sm={12} md={"auto"} lg={"auto"}>
            <Button
                variant="contained"
                size="small"
                sx={{ float: 'right' }}
                startIcon={<AddRoundedIcon />}
                onClick={handleClickOpen}
            >Distributor</Button>
        </Grid>
        <Grid item xs={12} sm={12} md={"auto"} lg={"auto"}>
            <Button disabled variant="contained" size="small" sx={{ float: 'right' }} endIcon={<FilterAltIcon />}>Filter</Button>
        </Grid>
    </Grid>
);
const contextActions = deleteHandler => (
    <IconButton onClick={deleteHandler}>
        <DeleteIcon />
    </IconButton>
);
const title = <Typography variant='h6'>{'Distributors'}</Typography>;
export default function Distributors(props) {
    dayjs.extend(localizedFormat);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);
    const [toggleCleared, setToggleCleared] = useState(false);
    const [queryInput, setQueryInput] = useState("");
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleSnakbarClose = () => {
        setMessage(false);
    }
    const handleChange = (s) => {
        setSelectedRows(s.selectedRows);
    }
    const deleteAll = () => {
        const rows = selectedRows.map(r => r.serial);
        if (window.confirm(`Are you sure you want to delete?`)) {
            setToggleCleared(!toggleCleared);
            setData(_.differenceBy(data, selectedRows, 'serial'));
        }
    }

    const handleQueryInput = (event) => {
        let query = event.target.value.trim().toLowerCase();
        setQueryInput(query);
    }

    const getData = async () => {
        setLoading(true);
        const response = await axios.get('/distributors');
        const data = await response.data;
        setData(data.data);
    }

    useEffect(() => { getData(); }, []);
    useEffect(() => {
        if (!_.isEmpty(data)) setLoading(false);
    }, [data]);

    return (
        <Box>
            {/* Sectoin Header */}
            <SectionHeader heading="Distributors" />
            <Box sx={{ flexGrow: 1, my: 2 }}>
                <DataTable
                    title={title}
                    columns={columns}
                    data={SearchQuery(queryInput, data)}
                    defaultSortField="name"
                    defaultSortFieldId={1}
                    actions={actions({ handleClickOpen, handleChange: handleQueryInput, getData })}
                    contextActions={contextActions(deleteAll)}
                    onSelectedRowsChange={handleChange}
                    selectableRowsNoSelectAll
                    clearSelectedRows={toggleCleared}
                    // onRowClicked={handleRowClicked}
                    progressPending={loading}
                />
            </Box>

            <FormDialog
                handleClickOpen={handleClickOpen}
                handleClose={handleClose}
                open={open}
                getData={getData}
                setMessage={setMessage}
            />
            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                open={message}
                onClose={handleSnakbarClose}
                autoHideDuration={4000}
            >
                <Alert severity="success" onClose={handleSnakbarClose}>
                    {
                        "Added successfully"
                    }
                </Alert>
            </Snackbar>
        </Box >
    );
}