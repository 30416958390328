import axios from "axios";
// import { onLogout } from "../store/actions/authentication.action";
import UserPool from "./UserPool";
// import { getFingerprint, getToken } from '../helper/utility'

axios.defaults.baseURL = 'https://seykb3hrda.execute-api.ap-south-1.amazonaws.com/Test/' //'http://localhost:5000';
// axios.defaults.baseURL = 'http://localhost:5000';
const interceptor = (store) => {

    // Interceptor requests
    axios.interceptors.request.use(
        (req) => {
            // req.headers['x-arad-fingerprint'] = getFingerprint();
            const currentUser = UserPool.getCurrentUser();
            currentUser.getSession((err, sess) => {
                if (err) {
                    return Promise.reject(err);
                } else {
                    const access_token = sess['idToken']['jwtToken'];
                    if (access_token) {
                        req.headers.Authorization = `${access_token}`;
                    }
                }
            })
            return req;
        },
        (err) => {
            return Promise.reject(err);
        }
    );

    // Intercepting Responses
    axios.interceptors.response.use(
        (res) => {
            if (res.status === 201) {
                // console.log("UPLOADED", res.data);
            }
            if (res.status === 200) {
                // console.log("SUCCESS", res.data);
            }
            return res;
        },
        (err) => {
            console.log('Interceptor', err);
            // if (err.response.status === 401) {
            //     console.log("ERROR", err.response.data);
            //     store.dispatch(onLogout());
            // }
            return Promise.reject(err);
        }
    );

}

export default interceptor;