import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

// MUI Component
import {
    Box, Typography, Grid, Button, IconButton
} from "@mui/material";
import {
    styled
} from "@mui/material/styles";
import CircularProgress from '@mui/material/CircularProgress';
// MUI icons
import AddIcon from "@mui/icons-material/Add";
import SyncIcon from '@mui/icons-material/Sync';
// Custom Components
import Search from "../../components/Search/Search.component";
import ProductCard from "../../components/Cards/ProductCard.component";
import Loading from "../../components/Common/Loading.component";
import _ from "lodash";
const Item = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

const Products = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [state, setState] = useState([]);
    const [error, setError] = useState({});
    const getData = async () => {
        setLoading(true);
        const response = await axios.get('/models');
        const result = await response.data;
        if (result.status) {
            // setLoading(false);
            setState(result.data);
        } else {
            setError(result);
        };
        setState(result.data);
    }
    useEffect(() => {
        getData()
    }, []);

    useEffect(() => {
        if (!_.isEmpty(state)) setLoading(false)
    }, [state]);

    return (
        <Box>
            <Box sx={{ flexGrow: 1, py: 2 }}>
                <Grid container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="flex-start"
                    spacing={1}
                >
                    <Grid item xs={2} md={"auto"}>
                        <Typography variant="h6" component="div">
                            {"Our Device Models"}
                        </Typography>
                    </Grid>
                    <Grid item xs={10} md={"auto"}>
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={12} md={"auto"} lg={"auto"}>
                                <IconButton aria-label="refresh" onClick={getData}>
                                    <SyncIcon />
                                </IconButton>
                            </Grid>
                            <Grid item xs={12} sm={12} md={"auto"} lg={"auto"}>
                                <Search placeholder="Search model..." />
                            </Grid>
                            <Grid item xs={12} sm={12} md={"auto"} lg={"auto"}>
                                <Button
                                    variant="contained"
                                    size="small"
                                    sx={{ float: 'right' }}
                                    startIcon={<AddIcon />}
                                    onClick={() => navigate("add")}
                                >Create Product</Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
            <Box>
                <Box sx={{
                    m: 0,
                    p: 1,
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                }}>
                    {
                        loading ?
                            <Loading size="2rem" />
                            :
                            state.map(d => (
                                <Item key={d._id}>
                                    <ProductCard info={d} />
                                </Item>
                            ))
                    }
                </Box>
            </Box>
        </Box >
    );
};
export default Products;